<template>
    <b-card no-body class="p-1">
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <b-row>
                <b-col cols="12" md="12">
                    <CryptoCurrencyTable :items="items" :columns="columns"/>
                </b-col>
            </b-row>
        </b-overlay>
    </b-card>
</template>

<script>
    import {
        BCard,
        BOverlay,
        BRow,
        BCol,
        // BButton,
    } from 'bootstrap-vue'
    import CryptoCurrencyTable from "@/views/CryptoCurrency/CryptoCurrencyTable";

    export default {
        name: "CryptoCurrency",
        components: {
            CryptoCurrencyTable,
            BCard,
            BOverlay,
            BRow,
            BCol,
        },
        data: () => ({
            dir: false,
            loading: false,
            coins: [],
            items: [],
            items1: [],
            data: {},
            edit: false,
            type: false,
            columns: [
                {
                    label: 'رمز ارز',
                    key: 'coin',
                    sortable: false,
                },
                {
                    label: 'کارمزد شبکه',
                    key: 'transferFee',
                    sortable: false,
                },
                {
                    label: 'حداقل برداشت',
                    key: 'minWithdrawAmount',
                    sortable: false,
                },
                {
                    label: 'حداکثر برداشت',
                    key: 'maxWithdrawAmount',
                    sortable: false,
                },
            ],
        }),
        methods: {
            async getData() {
                this.state.loading = true

                const res = await this.$axios.get('/coins')
                if (Object.hasOwnProperty.call(res.data.content[0],'minAmount')) {
                    this.columns.push(
                        {
                            label: 'حداقل سفارش',
                            key: 'minAmount',
                            sortable: false,
                        },
                        {
                            label: 'حداکثر سفارش',
                            key: 'maxAmount',
                            sortable: false,
                        },
                    )
                }
                if (Object.hasOwnProperty.call(res.data.content[0],'creditCardFee')) {
                    this.columns.push(
                        {
                            label: 'کارمزد کارت ها',
                            key: 'creditCardFee',
                            sortable: false,
                        },
                    )
                }
                this.items = res.data.content
            },
        },
        async created() {
            await this.getData()
        }
    }
</script>

<style scoped>

</style>