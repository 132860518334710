<template>
    <b-sidebar
            id="search-and-filter-sidebar"
            :visible="isActive"
            bg-variant="white"
            sidebar-class="sidebar-lg"
            shadow
            backdrop
            no-header
            right
            @change="(val) => $emit('update:is-active', val)"
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    جزییات سفارش کاربر {{order.fromName}}
                </h5>

                <feather-icon
                        class="ml-1 cursor-pointer"
                        icon="XIcon"
                        size="16"
                        @click="hide"
                />

            </div>

            <div class="d-flex flex-column px-2 py-1">
                <p>
                    شماره سفارش : 12458
                </p>
                <p>
                    مقدار سفارش : {{$toLocal(order.amount,$decimal[order.from])}} {{$coinLabel[order.from]}}
                </p>
                <p>
                    قیمت واحد : {{$toLocal(order.unitPrice,$decimal[order.to])}} {{$coinLabel[order.to]}}
                </p>
                <p>
                    انجام شده : {{$toLocal(order.gave,$decimal[order.from])}} {{$coinLabel[order.from]}}
                </p>
                <p>
                    انجام شده به درصد :
                    {{((order.gave/order.amount)*100).toFixed(2) + '%'}}
                </p>
                <p class="text-success">
                    نوع سفارش : {{typeLabel(order.type)}}
                </p>
                <p>
                    تاریخ ایجاد : {{order.created_at}}
                </p>
                <p class="text-danger">
                    وضعیت : لغو شده
                </p>
                <p>
                    اعمال وضعیت توسط : مدیر شماره 1
                </p>
                <p>
                    تاریخ آخرین تغییر : 1399-05-06 18:40
                </p>
                <p>
                    شماره پیگیری / هش لینک :
                    <a href="65as4df65as4d56as" target="_blank">65as4df65as4d56as</a>
                </p>
            </div>

        </template>
    </b-sidebar>
</template>

<script>
    import {
        BSidebar,
    } from 'bootstrap-vue'

    export default {
        name: 'historyDetails',
        components: {
            BSidebar,
        },
        model: {
            prop: 'isActive',
            event: 'update:is-active',
        },
        props: {
            isActive: {
                type: Boolean,
                required: true,
            },
            order: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                userData: [],
                select1Value: [],
                select2Value: [],
                cleave1Value: [],
                cleave2Value: [],
                keyValue: 0,
                date: '',
                number: '',
                selected: '',
            }
        },
        computed:{
            typeLabel() {
                const a = {
                    buy: 'خرید',
                    sell: 'فروش',
                }
                return e => a[e];
            }
        },
        methods: {

        }
    }
</script>

<style lang="scss">
    #add-new-user-sidebar {
        .vs__dropdown-menu {
            max-height: 200px !important;
        }
    }

    .input-group-prepend {
        .v-select {
            .vs__dropdown-toggle {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
</style>


