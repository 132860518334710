<template>
  <b-card no-body id="supervisor-bot" class="p-1 mb-1">
    <b-modal
        ref="deposit-modal"
        cancel-variant="outline-secondary"
        ok-title="واریز"
        cancel-title="لغو"
        centered
        title="جزییات سفارش"
        v-model="orderModal"
        hide-footer
        size="sm"
    >
      <b-list-group v-if="typeof selectedBot === 'number'">
        <b-list-group-item v-for="item in orderDetailsFields" :key="item + selectedBot"
                           class="d-flex justify-content-between align-items-center">
          <span>{{ item.label }}</span>
          <span v-if="items[selectedBot][item.key]">
            {{ item.handler ? item.handler(items[selectedBot][item.key]) : items[selectedBot][item.key] }}
            <b-badge v-if="item.small" pill variant="light-secondary" class="font-small-1">
              {{ item.small(items[selectedBot]) }}
            </b-badge>
          </span>
          <span v-else>
            {{ item.handler2 ? item.handler2(items[selectedBot][item.key2]) : items[selectedBot][item.key2] }}
            <b-badge v-if="item.small2" pill variant="light-secondary" class="font-small-1">
              {{ item.small2(items[selectedBot]) }}
            </b-badge>
          </span>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
    <b-table
        ref="refUserListTable"
        :items="items"
        :small="true"
        responsive
        :fields="columns"
        primary-key="id"
        show-empty
        empty-text="اطلاعاتی یافت نشد"
        style="white-space: nowrap; min-height : 200px"
        no-local-sort
    >
      <template #cell(marketType)="{value}">
        <b-avatar
            size="22"
            class="my-50"
            :src="require(`@/assets/images/Coins/${$M2RC(value)}.png`)"
            :variant="`light-info`"
        />
        /
        <b-avatar
            size="22"
            class="my-50"
            :src="require(`@/assets/images/Coins/${$M2SC(value)}.png`)"
            :variant="`light-info`"
        />
        <!--                - {{coinLabel(data.item.from)+' / '+coinLabel(data.item.to)}}-->
      </template>

      <template #cell(status)="{item,value}">
        <b-badge
            v-tooltip="item.message || ''"
            pill
            :variant="'light-'+StatusVariant(value)"
            class="text-capitalize"
            :badge="12"
        >
          {{ StatusLabel(value) }}
        </b-badge>
      </template>

      <template #cell(orderType)="{value}">
        <b-badge
            pill
            :variant="'light-'+orderVariant(value)"
            class="text-capitalize"
            :badge="12"
        >
          {{ orderLabel(value) }}
        </b-badge>
      </template>

      <template #cell(doOrder)="{value}">
        <b-badge
            pill
            :variant="'light-'+statusVariant(value)"
            class="text-capitalize"
            :badge="12"
        >
          {{ statusLabel(value) }}
        </b-badge>
      </template>

      <template #cell(stopInMax)="{value}">
        <b-badge
            pill
            :variant="'light-'+statusVariant(value)"
            class="text-capitalize"
            :badge="12"
        >
          {{ statusLabel(value) }}
        </b-badge>
      </template>

      <template #cell(doBetterPrice)="{value}">
        <b-badge
            pill
            :variant="'light-'+statusVariant(value)"
            class="text-capitalize"
            :badge="12"
        >
          {{ statusLabel(value) }}
        </b-badge>
      </template>

      <template #cell(lastRun)="{value}">
            <span dir="ltr">
              {{ $G2J(value, 'faTimes') }}
            </span>
      </template>

      <template #cell(nextRun)="{value}">
            <span dir="ltr">
              {{ $G2J(value, 'faTimes') }}
            </span>
      </template>

      <template #cell(updatedAt)="{value}">
            <span dir="ltr">
              {{ $G2J(value, 'faDateTime') }}
            </span>
      </template>

      <template #cell(action)="{item,index}">
        <template
            v-if="$havePermission('DETAILS_USERS')
              || $havePermission('UPDATE_USERS')
              || $havePermission('LIST_ORDERS')
              || $havePermission('LIST_TRADES')
              || $havePermission('LIST_MESSAGES')"
        >
          <b-dropdown
              id="dropdown-offset"
              variant="link"
              no-caret
              offset="80px"
              :right="true"
          >
            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="selectedBot=index;orderModal=true;">
              <feather-icon icon="ShoppingBagIcon"/>
              <span class="align-middle ml-50">جزییات سفارش</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="$havePermission('LIST_TRADES')" @click="$emit('edit',item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">اصلاح ربات</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="$havePermission('LIST_TRADES')" @click="changeBotStatus(item)">
              <feather-icon icon="RefreshCwIcon"/>
              <span class="align-middle ml-50">
                تغییر وضعیت
              </span>
            </b-dropdown-item>

            <b-dropdown-item v-if="$havePermission('LIST_TRADES')" @click="deleteBot(item)">
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">حذف ربات</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
        <not-allowed v-else/>
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard,
  // BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BListGroup,
  BListGroupItem,
  BBadge,
  BAvatar,
  // BForm,
  // BFormGroup,
  // BFormInput,
  // BFormTextarea,
  // BOverlay
} from 'bootstrap-vue'
// import vSelect from 'vue-select';
import NotAllowed from "@/layouts/components/NotAllowed";
import {
  authLabel,
  authVariant,
  orderVariant,
  orderLabel,
  statusVariant,
  statusLabel,
  hhmmss
} from "@/libs/methods";
import {io} from "socket.io-client";

let socket = ''

export default {
  name: "JobList",
  components: {
    NotAllowed,
    BCard,
    // BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    BBadge,
    BAvatar,
    // vSelect,
    // BForm,
    // BFormGroup,
    // BFormInput,
    // BFormTextarea,
    // BOverlay
  },
  data() {
    return {
      selectedBot: undefined,
      orderModal: false,
      items: [],
      // fields: ['last_run', 'next_run', 'updated_at'],
      orderDetailsFields: [
        {
          label: 'سفارش گذاری از',
          key: 'fromAmount',
          small: i => this.$coinLabel[this.$M2RC(i.marketType)]
        },
        {
          label: 'سفارش گذاری تا',
          key: 'toAmount',
          small: i => this.$coinLabel[this.$M2RC(i.marketType)]
        },
        {
          label: 'دقت مقدار سفارش',
          key: 'decimal',
        },
        {
          label: 'از ارزش',
          key: 'fromPriceAmount',
          key2: 'fromPricePercent',
          handler: e => this.$toLocal(Math.abs(e)),
          handler2: e => e ? `${Math.abs(e) * 100}%` : '',
          small: (i) => this.$coinLabel[this.$M2SC(i.marketType)] + ' ' + (i.fromPriceAmount < 0 ? 'کمتر از' : 'بیشتر از') + ' قیمت لحظه ای',
          small2: (i) => (i.fromPricePercent < 0 ? 'کمتر از' : i.fromPricePercent > 0 ? 'بیشتر از' : '') + 'قیمت لحظه ای'
        },
        {
          label: 'تا ارزش',
          key: 'toPriceAmount',
          key2: 'toPricePercent',
          handler: e => this.$toLocal(Math.abs(e)),
          handler2: e => e ? `${Math.abs(e) * 100}%` : '',
          small: (i) => this.$coinLabel[this.$M2SC(i.marketType)] + ' ' + (i.toPriceAmount < 0 ? 'کمتر از' : 'بیشتر از') + ' قیمت لحظه ای',
          small2: (i) => (i.toPricePercent < 0 ? 'کمتر از' : i.toPricePercent > 0 ? 'بیشتر از' : '') + 'قیمت لحظه ای'
        },
        {
          label: 'دقت قیمت سفارش',
          key: 'precision',
        },
        {
          label: 'تاریخ انقضا سفارش',
          key: 'exp',
          handler: e => hhmmss(e),
          small: () => 'بعد از ثبت سفارش',
          small2: () => 'بدون انقضا'
        },
      ],
      columns: [
        {
          label: 'نام کاربری',
          key: 'name',
          sortable: true,
        },
        {
          label: 'بازار',
          key: 'marketType',
          sortable: true,
        },
        {
          label: 'نوع سفارش',
          key: 'orderType',
          sortable: true,
        },
        {
          label: 'حداکثر سفارش فعال',
          key: 'max',
          sortable: false,
        },
        {
          label: 'انجام سفارش ها',
          key: 'doOrder',
          sortable: true,
        },
        {
          label: 'توقف در حداکثر',
          key: 'stopInMax',
          sortable: true,
        },
        {
          label: 'انجام قیمت های بهتر',
          key: 'doBetterPrice',
          sortable: true,
        },
        {
          label: 'آخرین اجرا',
          key: 'lastRun',
          sortable: true,
        },
        {
          label: 'اجرا بعدی',
          key: 'nextRun',
          sortable: true,
        },
        {
          label: 'آخرین آپدیت',
          key: 'updatedAt',
          sortable: true,
        },
        {
          label: 'وضعیت',
          key: 'status'
        },
        {
          label: 'مدیریت',
          key: 'action'
        },
      ],
    }
  },
  computed: {
    authLabel, authVariant,
    statusVariant,
    statusLabel,
    orderVariant,
    orderLabel,
    StatusVariant() {
      const a = {
        PENDING: 'success',
        DONE: 'success',
        STOPPED: 'danger',
        RUNNING: 'warning',
      }
      return e => a[e]
    },
    StatusLabel() {
      const a = {
        PENDING: 'در صف',
        DONE: 'در صف',
        STOPPED: 'متوقف شده',
        RUNNING: 'درحال اجرا',
      }
      return e => a[e]
    },
  },
  methods: {
    async deleteBot(e) {
      this.$swal({
        title: 'آیا از حذف ربات مطمئن هستید؟',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'بله',
        cancelButtonText: 'خیر',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.state.loading = true
          const [res,] = await this.$http.delete(this.$botLink + '/api/v01/job', {params: {id: e.id}})
          this.state.loading = false
          if (res) {
            this.$swal({
              icon: 'success',
              title: 'ربات با موفقیت حذف شد.',
              confirmButtonText: 'تایید',
              // text: 'Your file has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }
      })
    },
    async changeBotStatus(e) {
      const active = e.status !== 'STOPPED'
      this.$swal({
        title: 'آیا از تغییر وضعیت ربات مطمئن هستید؟',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'بله',
        cancelButtonText: 'خیر',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.state.loading = true
          const [res,] = await this.$http.put(this.$botLink + '/api/v01/job-status', {
            id: e.id,
            status: active ? 'STOPPED' : 'PENDING'
          })
          this.state.loading = false
          if (res) {
            this.$swal({
              icon: 'success',
              title: active ? 'ربات غیر فعال شد' : 'ربات فعال شد',
              confirmButtonText: 'تایید',
              // text: 'Your file has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }
      })
    },
    async getData() {
      this.state.loading = true
      const [res] = await this.$http.get(
          this.$botLink + '/api/v01/jobs',
      )
      if (res) {
        this.items = res.data
      }
      this.state.loading = false
    },
    connectSocket() {
      socket = io(this.$botLink);
      socket.on('connect', (e) => {
        console.log(e)
      })
      socket.on('update', (e) => {
        this.items = e
      })
    }
  },
  mounted() {
    this.getData()
    this.connectSocket()
  },
  beforeDestroy() {
    socket.off();
  }
}
</script>

<style scoped>

</style>