import Vue from "vue";

let that = Vue.prototype

that.$sleep = (ms) => (new Promise(resolve => setTimeout(resolve, ms)))

that.$p2e = string => string.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))

that.$toLocal = (number, decimal = 8) => {
    //purify number
    number = number + ''
    if (number === '.') {
        if (decimal)
            return '0.'
        else
            return ''
    }

    let n = number.startsWith('-') ? '-' : ''

    if (number.toLowerCase().includes('e')) {
        if (parseFloat(number) < 1.0e-7) {
            //zero numbers
            return 0
        } else
            number = parseFloat(number) + ''
    }

    number = that.$p2e(number).replace(/[^\d.]|\.{2}|,/ig, '')
    if (number.includes('.')) {
        number = number.split('.')[0] + '.' + (number.split('.')[1] || '')
    }

    if (parseFloat(number)) {
        //separate number
        const int = parseFloat(number.split('.')[0]).toLocaleString('en')
        if (number.includes('.') && decimal) {
            let precision = (number.split('.')[1].slice(0, decimal) || '')
            return n + int + '.' + precision
        } else {
            return n + int
        }
    } else {
        return number
    }

}
export const $toLocal = that.$toLocal

that.$S2N = string => parseFloat((string + '').replace(/,/g, ''))
that.$A2N = string => parseFloat((string + '').replace(/[^\d.]/ig, ''))

const faString = '[\\w!#^+=?*&@.$%{}\\[\\]\'\\\\\\-,\\(\\):;`|<>/~"]|\\s{2}'
const faRegex = new RegExp(faString, "ig")
that.$S2Fa = string => (string + '').replace(faRegex, '')

that.$marketType2relatedCoin = marketType => marketType.replace(/_TOMAN|_TETHER/, '')
that.$M2RC = marketType => (marketType + '').replace(/_TOMAN|_TETHER/, '')
that.$marketType2sourceCoin = marketType => marketType.includes('TOMAN') ? 'TOMAN' : 'TETHER'
that.$M2SC = marketType => (marketType + '').includes('TOMAN') ? 'TOMAN' : 'TETHER'

// eslint-disable-next-line no-useless-escape
that.$separator = (target, repeatCount = 4, separator = '-', type = 'd', regString = "\\d{1,4}") => {
    //make filter regex
    let replaceString = '[^\\d]'
    replaceString = replaceString.replace(/d/ig, type)
    const regex1 = new RegExp(replaceString, "g")

    //filter target
    target = target + ''
    target = target.replace(regex1, '')

    if (target) {
        //make separator regex
        regString = regString.replace(/d/ig, type)
        regString = regString.replace(/4/g, repeatCount)
        const regex = new RegExp(regString, "g");

        //separate
        return target.match(regex).join(separator)
    }
}

that.$error = async (title = '', text = '', icon = 'error', button = 'متوجه شدم') => {
    await that.$swal({
        icon: icon,
        title: title,
        text: text,
        confirmButtonText: button,
        customClass: {
            confirmButton: 'btn-success',
        },
    })
}

that.$count = (find, array) => {
    let count = 0;
    for (let i = 0; i < array.length; i++)
        if (array[i].includes(find))
            count++;
    return count;
}

that.$toSnakeCase = str =>
    str &&
    str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.toUpperCase())
        .join('_');

that.$numFormatter = num =>
    num < 1000 ? num.toLocaleString()
        : num < 1000000 ? `${(num / 1000).toFixed(1).toLocaleString()}k`
            : num < 1000000000 ? `${(num / 1000000).toFixed(1).toLocaleString()}m`
                : `${(num / 1000000000).toFixed(1).toLocaleString()}b`;

that.$getUserInfo = async () => {
    let info = await that.$axios('admins/info')
    info = info.data.baseDTO

    // const roleDetails = await that.$axios(`/roles/${info.role.id}`)

    that.state.userInfo = {
        firstName: info.firstName,
        lastName: info.lastName,
        role: info.role.englishName,
        rolePersian: info.role.persianName,
        privileges: info.role.privileges,
    }

    if (that.state.userInfo.privileges.includes('LIST_WITHDRAW')) {
        const requestCount = await that.$axios('/wallets/withdrawal-requests?withdrawalRequestStatus=RAISED_BY_USER')
        that.state.requestCount = requestCount.data.totalElements
    }

    console.log(window.navigator.appCodeName,)

    that.state.gotten = true
}

that.$makeBookmarkData = async (e) => {
    let data = []
    const loop = async (e, title = '', icon = '') => {
        await e.forEach(el => {
            if (!el.children) {
                if (!title) {
                    data.push(
                        {
                            title: el.title,
                            route: {name: el.route},
                            icon: el.icon,
                            isBookmarked: localStorage.bookmarks ? JSON.parse(localStorage.bookmarks).includes(el.title) : false,
                        }
                    )
                } else {
                    data.push(
                        {
                            route: {name: el.route},
                            title: title + "/ " + el.title,
                            icon: icon,
                            isBookmarked: localStorage.bookmarks ? JSON.parse(localStorage.bookmarks).includes(title + "/ " + el.title) : false,
                        }
                    )
                }
            } else {
                loop(el.children, (title ? `${title}/ ` : '') + el.title, icon ? icon : el.icon, data)
            }
        })
    }
    await loop(e)
    return data
}

that.$filter = (data) => {

    for (let i = 0; i < data.length; i++) {
        let remove = true
        for (let role of data[i].role) {
            if (role === 'all') {
                remove = false;
                continue;
            }
            if (that.state.userInfo.privileges.includes(role)) {
                remove = false
                break;
            }
        }
        if (remove) {
            data.splice(i, 1)
            i--
        } else {
            if (data[i].children)
                that.$filter(data[i].children)
        }
    }
}

that.$havePermission = (e) => {
    return that.state.userInfo.privileges.includes(e)
}

that.$logout = () => {
    localStorage.clear('token')
    that.state.token = ''
    that.state.gotten = false
    that.$axios.defaults.headers.common['Authorization'] = that.state.token
    try {
        that.$router.push('/login')
    } catch (e) {
        location.reload()
    }

}

that.$test = (e) => {
    console.log('event test : ', e)
}

import './georgianToJalali'
