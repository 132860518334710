<template>
  <b-card no-body class="p-1">
    <b-overlay
        :show="state.loading"
        rounded="sm"
    >
      <search-and-filter
          :is-active.sync="isActive"
          :options="columns"/>

      <!--            diposit-modal-->
      <b-modal
          ref="dip-modal"
          cancel-variant="outline-secondary"
          ok-title="بررسی واریز"
          cancel-title="لغو"
          centered
          :title="'واریز '+cryptoName"
          @ok.prevent="checkWallet"
          v-model="dipModal"
      >
        <b-overlay :show="loading">
          <h5>
            جهت واریز به کیف پول {{ cryptoName }} سایت خود از QRcode آدرس زیر استفاده نمایید
          </h5>
          <p v-tooltip="'کپی آدرس'" class="text-center py-1 cursor-pointer" @click="copy(depositAddress)">
            {{ depositAddress }}
          </p>
          <b-card
              :img-src="'data:image/gif;base64,'+QRcode"
              img-alt="Card image cap"
              img-top
              no-body
              style="max-width:300px"
              class="mx-auto my-1"
          >
          </b-card>
          <b-form-group label="هش تراکنش" v-if="$ethBase.includes(depositRequest.relatedCoin)">
            <b-form-input
                id="txid"
                type="text"
                :state="!!depositRequest.txid"
                placeholder="txID..."
                v-model="depositRequest.txid"
            />
          </b-form-group>
        </b-overlay>

      </b-modal>
      <!--            withdraw-modal-->
      <b-modal
          ref="with-modal"
          cancel-variant="outline-secondary"
          ok-title="ارسال"
          cancel-title="لغو"
          centered
          :title="'برداشت '+ $coinLabel[withdrawRequest.relatedCoin]"
          @ok.prevent="submitWithdraw"
          @cancel.prevent="() => {if(!loading) this.withModal=false}"
          v-model="withModal"
      >
        <b-overlay
            :show="loading"
            rounded="sm"
        >
          <b-form>
            <b-form-group label="مقدار درخواستی">
              <b-form-input
                  id="value"
                  type="text"
                  :state="!!$S2N(withdrawRequest.value)"
                  :placeholder="'مقدار ' + $coinLabel[withdrawRequest.relatedCoin]"
                  v-model="withdrawRequest.value"
              />
            </b-form-group>
            <b-form-group :state="!!withdrawRequest.tokenType" label="نوع توکن"
                          v-if="withdrawRequest.relatedCoin==='TETHER'">
              <v-select
                  v-model="withdrawRequest.tokenType"
                  dir="rtl"
                  :options="[{name : 'اتریومی' , value : 'ERC20'},{name : 'ترونی' , value : 'TRC20'}]"
                  label="name"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="role"
                  style="min-width:105px"
              />
            </b-form-group>
            <b-form-group label="آدرس کیف پول مقصد">
              <b-form-input
                  id="address"
                  type="text"
                  :state="!!withdrawRequest.outputAddress"
                  placeholder="0Xd54d"
                  v-model="withdrawRequest.outputAddress"
              />
            </b-form-group>
            <b-form-group label="شناسه برداشت"
                          v-if="['STELLAR','RIPPLE'].includes(withdrawRequest.relatedCoin)">
              <b-form-input
                  id="tag"
                  type="text"
                  :state="!!withdrawRequest.tag"
                  placeholder=""
                  v-model="withdrawRequest.tag"
              />
            </b-form-group>
            <b-form-group label="رمز کیف پول">

              <b-input-group class="mb-1">
                <b-form-input :state="!!withdrawRequest.password" dir="ltr" class="text-left"
                              v-model="withdrawRequest.password"  placeholder="رمز کیف پول"
                              :type="passwordType"/>
                <b-input-group-append is-text>
                  <feather-icon
                      :icon="passwordType==='password'?'EyeIcon':'EyeOffIcon'"
                      class="cursor-pointer"
                      @click="passwordType = passwordType==='password'? 'text':'password'"
                  />
                </b-input-group-append>
              </b-input-group>

            </b-form-group>
          </b-form>
        </b-overlay>
      </b-modal>
      <!--            mnemonic-modal-->
      <b-modal
          ref="mn-modal"
          cancel-variant="outline-secondary"
          ok-title="ارسال"
          cancel-title="لغو"
          centered
          :title="'دریافت mnemonic کیف پول '+mnRequest.walletName"
          @ok.prevent="getMnemonic"
          @cancel.prevent="() => {if(!loading) this.mnModal=false}"
          v-model="mnModal"
      >
        <b-overlay
            :show="loading"
            rounded="sm"
        >
          <b-form @submit.prevent="getMnemonic">
            <b-form-group label="رمز کیف پول">

              <b-input-group class="mb-1">
                <b-form-input :state="!!mnRequest.password" dir="ltr" class="text-left"
                              v-model="mnRequest.password"  placeholder="رمز کیف پول"
                              :type="passwordType"/>
                <b-input-group-append is-text>
                  <feather-icon
                      :icon="passwordType==='password'?'EyeIcon':'EyeOffIcon'"
                      class="cursor-pointer"
                      @click="passwordType = passwordType==='password'? 'text':'password'"
                  />
                </b-input-group-append>
              </b-input-group>

            </b-form-group>
            <p v-if="mnRequest.mnemonic" v-tooltip="'کپی mnemonic'" class="text-center py-1 cursor-pointer"
               @click="copy(mnRequest.mnemonic)">
              {{ mnRequest.mnemonic }}
            </p>
          </b-form>
        </b-overlay>
      </b-modal>
      <!--            vandar-modal-->
      <b-modal
          ref="vandar-modal"
          title="ورود به وندار"
          ok-title="مشاهده موجودی"
          cancel-title="لغو"
          v-model="vandarModal"
          @ok.prevent="getVandarWallet"
      >
        <b-form>
          <b-form-group class="mt-1" label="نام کاربری حساب وندار">
            <b-form-input v-model="vandar.mobile" placeholder="نام کاربری"/>
          </b-form-group>
          <b-form-group class="mt-1" label="رمز عبور حساب وندار">
            <b-form-input v-model="vandar.password" placeholder="رمز عبور" type="password"/>
          </b-form-group>
        </b-form>
      </b-modal>
      <!--            address-modal-->
      <!--<b-modal
              ref="address-modal"
              cancel-variant="outline-secondary"
              ok-title="ارسال "
              cancel-title="لغو"
              centered
              :title="'ساخت آدرس برای کیف پول '+addAddressData.walletName"
              @ok.prevent="submitAddressCount"
              @cancel.prevent="() => {if(!loading) this.addressModal=false}"
              v-model="addressModal"
      >
          <b-overlay
                  :show="loading"
                  rounded="sm"
          >
              <b-form>
                  <b-form-group label="تعداد آدرس درخواستی">
                      <b-form-input
                              id="count"
                              type="text"
                              :placeholder="'تعداد آدرس های ' + cryptoName"
                              v-model="addAddressData.addressCount"
                      />
                  </b-form-group>
                  <b-form-group v-if="!['ETHEREUM','CLASSIC_ETHEREUM','TETHER'].includes(cryptoUnit)"
                                label="رمز کیف پول">
                      <b-form-input
                              type="password"
                              placeholder="رمز"
                              v-model="addAddressData.password"
                      />
                  </b-form-group>
              </b-form>
          </b-overlay>
      </b-modal>-->
      <!--            new-wallet-->
      <b-modal
          ref="new-wallet-modal"
          cancel-variant="outline-secondary"
          ok-title="ساخت کیف پول"
          cancel-title="لغو"
          centered
          title="ساخت کیف پول"
          @ok.prevent="addWallet"
          v-model="newWalletModal"
      >
        <b-form>
          <b-overlay
              :show="loading"
              rounded="sm"
          >
            <b-form-group label="نوع کیف پول">
              <v-select
                  v-model="wallet.relatedCoin"
                  dir="rtl"
                  :key="wallet.relatedCoin.length"
                  :options="coins"
                  label="label"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="role"
                  style="min-width:105px"
              />
            </b-form-group>
            <b-form-group label="نام کیف پول">
              <b-form-input v-model="wallet.walletName"/>
            </b-form-group>
            <b-form-group label="رمز کیف پول" v-if="wallet.relatedCoin !== 'TETHER'">

              <b-input-group class="mb-1">
                <b-form-input :state="wallet.password.length>9" dir="ltr" class="text-left"
                              v-model="wallet.password"  placeholder="رمز کیف پول"
                              :type="passwordType"/>
                <b-input-group-append is-text>
                  <feather-icon
                      :icon="passwordType==='password'?'EyeIcon':'EyeOffIcon'"
                      class="cursor-pointer"
                      @click="passwordType = passwordType==='password'? 'text':'password'"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-input-group class="mb-1">
                <b-form-input :state="wallet.password===password" dir="ltr" class="text-left"
                              :type="secondPasswordType"
                              v-model="password"  placeholder="تکرا رمز کیف پول"/>
                <b-input-group-append is-text>
                  <feather-icon
                      :icon="secondPasswordType==='password'?'EyeIcon':'EyeOffIcon'"
                      class="cursor-pointer"
                      @click="secondPasswordType = secondPasswordType==='password'? 'text':'password'"
                  />
                </b-input-group-append>
              </b-input-group>

            </b-form-group>
            <!--<b-form-group label="کلید امنیتی 12 کلمه ای" v-else-if="wallet.relatedCoin !== 'TETHER'">

                <div class="bv-no-focus-ring col-form-label py-10 d-flex justify-content-between">
                    ساخت کلید توسط سیستم
                    <b-form-checkbox
                            switch
                            inline
                            class="mr-0"
                            v-model="wallet.generateMnemonic"
                            @input="wallet.mnemonic = ''"
                    >
                    </b-form-checkbox>
                </div>

                <b-input-group class="mb-1" v-if="!wallet.generateMnemonic">
                    <b-form-textarea
                            :state="mnemonicRegex(wallet.mnemonic)"
                            dir="ltr" class="text-left"
                            v-model="wallet.mnemonic"  placeholder="وارد کردن دستی"
                            :readonly="wallet.generateMnemonic"/>
                </b-input-group>

            </b-form-group>-->
            <!--                        <b-form-group label="از کیف پول" v-else>
                                        <v-select
                                                v-model="wallet.walletId"
                                                dir="rtl"
                                                :key="wallet.relatedCoin"
                                                :options="items.filter(e => ['TRON','ETHEREUM'].includes(e.relatedCoin))"
                                                label="walletName"
                                                :reduce="val => val.id"
                                                :clearable="false"
                                                input-id="role"
                                                style="min-width:105px"
                                        />
                                    </b-form-group>-->
            <b-form-group label="نوع توکن" v-else>
              <v-select
                  v-model="wallet.tokenType"
                  dir="rtl"
                  :key="wallet.relatedCoin"
                  :options="[{name : 'اتریومی' , value : 'ERC20'},{name : 'ترونی' , value : 'TRC20'}]"
                  label="name"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="role"
                  style="min-width:105px"
              />
            </b-form-group>
          </b-overlay>
        </b-form>
      </b-modal>
      <!--            change-pass-->
      <b-modal
          ref="new-wallet-modal"
          cancel-variant="outline-secondary"
          ok-title="تغییر رمز کیف پول"
          cancel-title="بازگشت"
          centered
          title="تغییر رمز"
          @ok.prevent="changePass"
          v-model="changeModal"
      >
        <b-form>
          <b-overlay
              :show="loading"
              rounded="sm"
          >
            <b-form-group label="نوع کیف پول">
              <b-form-input readonly :value="$coinLabel[selectedWallet.relatedCoin]"/>
            </b-form-group>
            <b-form-group label="نام کیف پول">
              <b-form-input readonly :value="selectedWallet.walletName"/>
            </b-form-group>
            <b-form-group label="رمز فعلی کیف پول">

              <b-input-group class="mb-1">
                <b-form-input :state="selectedWallet.oldPassword.length>9" dir="ltr" class="text-left"
                              v-model="selectedWallet.oldPassword"  placeholder="رمز فعلی کیف پول"
                              :type="passwordType"/>
                <b-input-group-append is-text>
                  <feather-icon
                      :icon="passwordType==='password'?'EyeIcon':'EyeOffIcon'"
                      class="cursor-pointer"
                      @click="passwordType = passwordType==='password'? 'text':'password'"
                  />
                </b-input-group-append>
              </b-input-group>

            </b-form-group>
            <b-form-group label="رمز جدید">

              <b-input-group class="mb-1">
                <b-form-input :state="selectedWallet.password.length>9" dir="ltr" class="text-left"
                              v-model="selectedWallet.password"  placeholder="رمز جدید کیف پول"
                              :type="secondPasswordType"/>
                <b-input-group-append is-text>
                  <feather-icon
                      :icon="secondPasswordType==='password'?'EyeIcon':'EyeOffIcon'"
                      class="cursor-pointer"
                      @click="secondPasswordType = secondPasswordType==='password'? 'text':'password'"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-input-group class="mb-1">
                <b-form-input :state="!!selectedWallet && selectedWallet.password===confirmPass"
                              dir="ltr" class="text-left"
                              :type="secondPasswordType"
                              v-model="confirmPass"  placeholder="تکرار رمز جدید"/>
                <b-input-group-append is-text>
                  <feather-icon
                      :icon="secondPasswordType==='password'?'EyeIcon':'EyeOffIcon'"
                      class="cursor-pointer"
                      @click="secondPasswordType = secondPasswordType==='password'? 'text':'password'"
                  />
                </b-input-group-append>
              </b-input-group>

            </b-form-group>
          </b-overlay>
        </b-form>
      </b-modal>
      <!--            accumulate-->
      <b-modal
          ref="new-wallet-modal"
          cancel-variant="outline-secondary"
          ok-title="تجمیع ارزها "
          cancel-title="لغو"
          centered
          title="تجمیع"
          @ok.prevent="accumulate"
          v-model="accumulateModal"
      >
        <b-form>
          <b-overlay
              :show="loading"
              rounded="sm"
          >
            <b-form-group label="شناسه درخواست">

              <b-input-group class="mb-1">
                <b-form-input :state="!!accumulateWallet.label" dir="ltr"
                              class="text-right"
                              v-model="accumulateWallet.label"
                              placeholder="شناسه درخواست"/>
              </b-input-group>

            </b-form-group>

            <b-form-group label="حداقل موجودی حساب">

              <b-input-group class="mb-1" v-tooltip="'حداقل موجودی : ' + minFee">
                <b-form-input :state="parseFloat(accumulateWallet.minBalance)>=parseFloat(minFee)"
                              dir="ltr" class="text-right"
                              v-model="accumulateWallet.minBalance"
                              placeholder="حداقل موجودی حساب"/>
              </b-input-group>

            </b-form-group>
            <b-form-group label="حداکثر کارمزد مصرفی" v-if="accumulateWallet.relatedCoin==='TETHER'">

              <b-input-group class="mb-1">
                <b-form-input :state="accumulateWallet.maxBurn>=0" dir="ltr" class="text-right"
                              v-model="accumulateWallet.maxBurn"
                              placeholder="حداکثر کارمزد مصرفی"/>
              </b-input-group>

            </b-form-group>
            <b-form-group label="رمز کیف پول">

              <b-input-group class="mb-1" v-tooltip="'حداقل 10 کاراکتر'">
                <b-form-input :state="accumulateWallet.password.length>9" dir="ltr" class="text-left"
                              v-model="accumulateWallet.password"  placeholder="رمز کیف پول"
                              :type="passwordType"/>
                <b-input-group-append is-text>
                  <feather-icon
                      :icon="passwordType==='password'?'EyeIcon':'EyeOffIcon'"
                      class="cursor-pointer"
                      @click="passwordType = passwordType==='password'? 'text':'password'"
                  />
                </b-input-group-append>
              </b-input-group>

            </b-form-group>
            <!--<b-form-group label="کلید امنیتی 12 کلمه ای" v-else>

                <b-input-group class="mb-1">
                    <b-form-textarea
                            :state="mnemonicRegex(accumulateWallet.mnemonic)"
                            dir="ltr" class="text-left"
                            v-model="accumulateWallet.mnemonic"  placeholder="12 key phrase"
                    />
                </b-input-group>

            </b-form-group>-->
          </b-overlay>
        </b-form>
      </b-modal>

      <!--header-->
      <div v-if="$havePermission('CREATE_WALLET')" class="demo-spacing-0 d-flex justify-content-between m-1"
           dir="rtl">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info" @click="createWallet">
          <feather-icon size="16" icon="DollarSignIcon"/>
          ساخت کیف پول جدید
        </b-button>
      </div>

      <div class="relative-over-x">
        <b-table
            ref="refUserListTable"
            :items="items"
            :small="true"
            responsive
            :fields="columns"
            primary-key="id"
            show-empty
            empty-text="اطلاعاتی یافت نشد"
            class="text-nowrap"
            style="min-height : 300px"
        >
          <template #cell(relatedCoin)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="32"
                    :src="require(`@/assets/images/Coins/${data.item.relatedCoin}.png`)"
                    :variant="`light-info`"
                    :to="data.item.relatedCoin!=='TOMAN' ? { name:  'statistics', params: { id: data.item.relatedCoin+'_'+'TOMAN' } } : ''"
                />
              </template>
              <component :is="data.item.relatedCoin!=='TOMAN' ? 'b-link' : 'span'"
                         :to="{ name: 'statistics', params: { id: data.item.relatedCoin+'_'+'TOMAN' }}"
                         class="font-weight-bold d-block text-nowrap"
              >
                {{ $coinLabel[data.item.relatedCoin] }}
              </component>
              <small class="text-muted">{{ data.item.relatedCoin }}</small>
            </b-media>
          </template>

          <template #cell(totalBalance)="data">
                        <span dir="ltr">
                            {{ $toLocal(data.item.totalBalance, $decimal[data.item.relatedCoin]) || 0 }}
                        </span>
          </template>

          <template #cell(totalCredit)="data">
                        <span dir="ltr">
                            {{ $toLocal(data.item.totalCredit, $decimal[data.item.relatedCoin]) || 0 }}
                        </span>
          </template>

          <template #cell(addressesCount)="data">
            <b-badge
                pill
                :variant="'light-'+addressVariant(data.item.assignedAddressesCount,data.item.addressesCount)"
                class="text-capitalize"
            >
              {{ data.item.assignedAddressesCount }}/{{ data.item.addressesCount }}
            </b-badge>
          </template>

          <template #cell(status)="data">
            <b-badge
                pill
                :variant="'light-'+statusVariant(data.item.status)"
                class="text-capitalize"
            >
              {{ statusLabel(data.item.status) }}
            </b-badge>
          </template>

          <template #cell(action)="data">
            <template v-if="$havePermission('CREATE_WALLET') || $havePermission('DETAILS_WALLET')">
              <b-dropdown
                  id="dropdown-offset"
                  variant="link"
                  no-caret
                  offset="80px"
                  :right="true"
              >
                <template #button-content>
                  <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item
                    v-if="$havePermission('CREATE_WALLET') && data.item.relatedCoin!=='TOMAN'"
                    @click="deposit(data.item)" @click.shift="checkWallet(data.item)">
                  <feather-icon icon="DownloadIcon"/>
                  <span class="align-middle ml-50">واریز به کیف پول</span>
                </b-dropdown-item>

                <b-dropdown-item
                    v-if="$havePermission('CREATE_WALLET') && data.item.relatedCoin!=='TOMAN'"
                    @click="withdraw(data.item)">
                  <feather-icon icon="UploadIcon"/>
                  <span class="align-middle ml-50">برداشت از کیف پول</span>
                </b-dropdown-item>

                <b-dropdown-item
                    v-if="$havePermission('CREATE_WALLET') && data.item.relatedCoin!=='TOMAN'"
                    @click="changePassModal(data.item)">
                  <feather-icon icon="KeyIcon"/>
                  <span class="align-middle ml-50">تغییر رمز</span>
                </b-dropdown-item>

                <b-dropdown-item
                    v-if="$havePermission('CREATE_WALLET') && data.item.relatedCoin!=='TOMAN'"
                    @click="mnemonic(data.item)">
                  <feather-icon icon="KeyIcon"/>
                  <span class="align-middle ml-50">دریافت mnemonic</span>
                </b-dropdown-item>

                <b-dropdown-item v-if="data.item.relatedCoin==='TOMAN'" @click="getVandarWallet">
                  <feather-icon icon="DollarSignIcon"/>
                  <span class="align-middle ml-50">موجودی کیف پول وندار</span>
                </b-dropdown-item>

                <b-dropdown-item v-if="$havePermission('LIST_TRANSACTIONS')"
                                 :to="{name:history(data.item.relatedCoin),query:{relatedCoin : data.item.relatedCoin}}">
                  <feather-icon icon="ClockIcon"/>
                  <span class="align-middle ml-50">سوابق تراکنش</span>
                </b-dropdown-item>

                <b-dropdown-item v-if="$havePermission('DETAILS_WALLET')"
                                 :to="{name: address(data.item.relatedCoin) ,query:{walletName : data.item.walletName}}">
                  <feather-icon icon="ListIcon"/>
                  <span
                      class="align-middle ml-50">{{
                      data.item.relatedCoin === 'TOMAN' ? 'مشاهده کیف پول ها' : 'مشاهده آدرس ها'
                    }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                    v-if="!['BITCOIN','DOGE_COIN','LITE_COIN','BITCOIN_CASH','STELLAR','RIPPLE','TOMAN'].includes(data.item.relatedCoin) && $havePermission('UPDATE_WALLET')"
                    @click="coinAccumulation(data.item)">
                  <feather-icon icon="DollarSignIcon"/>
                  <span class="align-middle ml-50">تجمیع ارز ها</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <not-allowed v-else/>
          </template>
        </b-table>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  // BPagination,
  BCard,
  // BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  BMedia,
  BAvatar,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BInputGroupAppend,
  BButton,
  BOverlay,
  BLink,
  // BRow,
  // BCol,
  // BFormInvalidFeedback,
} from 'bootstrap-vue'
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
// import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select';
import NotAllowed from "@/layouts/components/NotAllowed";

export default {
  name: 'Wallets',
  components: {
    NotAllowed,
    SearchAndFilter,
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    BBadge,
    BMedia,
    BAvatar,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    BInputGroupAppend,
    BOverlay,
    BLink,
    vSelect,
  },
  data: () => ({
    test: 0,
    pageLength: 3,
    dir: false,
    searchTerm: '',
    currentPage: 1,
    perPage: 1,
    rows: 200,
    sortBy: '',
    cryptoName: '',
    cryptoUnit: '',
    walletName: '',
    QRcode: '',
    vandar: {
      mobile: '',
      password: ''
    },
    withdrawRequest: {
      walletName: '',
      password: '',
      value: '',
      outputAddress: ''
    },
    selectedWallet: {
      walletName: '',
      id: '',
      password: '',
      oldPassword: '',
    },
    confirmPass: '',
    mnRequest: {
      walletName: '',
      password: '',
      mnemonic: ''
    },
    depositRequest: {
      txid: ''
    },
    addAddressData: {
      walletName: '',
      password: '',
      addressCount: ''
    },
    depositAddress: '',
    isSortDirDesc: '',
    userData: [],
    isActive: false,
    addressModal: false,
    withModal: false,
    dipModal: false,
    mnModal: false,
    feeModal: false,
    changeModal: false,
    accumulateModal: false,
    accumulateWallet: {
      password: '',
      minBalance: '',
      maxBurn: '',
      label: ''
    },
    vandarModal: false,
    totalBalance: '',
    loading: false,
    wallet: {walletName: '', password: '', relatedCoin: 'BITCOIN', addressCount: 1},
    password: '',
    passwordType: 'password',
    secondPasswordType: 'password',
    newWalletModal: false,
    perPageOptions: [5, 10, 20, 50, 100],
    coins: [],
    items: [],
    columns: [
      {
        label: 'رمز ارز',
        key: 'relatedCoin',
        sortable: true,
        searchType: 'text'
      },
      {
        label: 'نام کیف پول',
        key: 'walletName',
        sortable: true,
        searchType: 'number'
      },
      {
        label: 'موجودی',
        key: 'totalBalance',
        sortable: true,
        searchType: 'number'
      },
      {
        label: 'اعتبار کیف پول',
        key: 'totalCredit',
        sortable: true,
        searchType: 'number'
      },
      {
        label: 'تعداد آدرس های کیف پول',
        key: 'addressesCount',
        sortable: false,
        searchType: 'text',
      },
      {
        label: 'عملیات',
        key: 'action'
      },
    ],
    minFee: 0,
  }),
  watch: {
    'wallet.addressCount'(val) {
      val.replace(/([A-Z,a-z])\w+/g, '')
      this.wallet.addressCount = val
    },
    'withdrawRequest.value'() {
      this.withdrawRequest.value = this.$toLocal(this.withdrawRequest.value)
    },
  },
  computed: {
    changePassconf() {
      return this.selectedWallet.password === this.confirmPass
    },
    addressVariant() {
      return (a, b) => a / b < 0.5 ? 'success' : a / b > 0.9 ? 'danger' : 'warning';
    },
    history() {
      return e =>
          ['ETHEREUM', 'BINANCE_CHAIN_BEP20', 'MATIC', 'CELO', 'ELROND', 'TETHER'].includes(e) ? 'ethereum-history'
              : ['TRON', 'TETHER'].includes(e) ? 'tron-history'
                  : ['STELLAR', 'RIPPLE'].includes(e) ? 'x-history'
                      : e === 'TOMAN' ? 'toman-transactions'
                          : 'wallet-transactions'
    },
    address() {
      return e => ['STELLAR', 'RIPPLE'].includes(e) ? 'x-addresses' : e === 'TOMAN' ? 'toman-addresses' : 'hd-addresses'
    },
    allowCreateWallet() {
      /*if (['STELLAR', 'RIPPLE'].includes(this.wallet.relatedCoin)) {
          return this.password === this.wallet.password && this.wallet.password.length > 9
      } else*/
      if (this.wallet.relatedCoin !== 'TETHER') {
        return this.wallet.password && this.password === this.wallet.password && this.wallet.password.length > 9
      } else {
        return this.wallet.tokenType
      }
    },
    allowChangePass() {
      return this.selectedWallet.password === this.confirmPass && this.selectedWallet.oldPassword.length > 9 && this.selectedWallet.password.length > 9
    },
    allowWithdraw() {
      if (!this.withdrawRequest.id || !this.withdrawRequest.password || !this.$S2N(this.withdrawRequest.value) || !this.withdrawRequest.outputAddress) {
        return false
      } else if (['STELLAR', 'RIPPLE'].includes(this.wallet.relatedCoin)) {
        return this.withdrawRequest.tag
      } else if (this.withdrawRequest.relatedCoin === 'TETHER') {
        return this.withdrawRequest.tokenType
      } else {
        return true
      }
    },
    allowAccumulate() {
      return this.accumulateWallet.password.length > 9
          && parseFloat(this.accumulateWallet.minBalance) >= parseFloat(this.minFee)
          && this.accumulateWallet.maxBurn >= 0
          && !!this.accumulateWallet.label
    },
    mnemonicRegex() {
      return e => /^\s*\S+(?:\s+\S+){11,}\s*$/.test(e)
    }
  },
  methods: {
    submit() {
      this.dipModal = false
      this.withModal = false
    },
    copy(e) {
      navigator.clipboard.writeText(e)
      alert('متن کپی شد')
    },
    async getVandarWallet() {
      try {
        this.state.loading = true
        const res = await this.$axios.post('/wallets/vandar-wallet', this.vandar)
        this.$error('موجودی کیف پول شما', res.data.baseDTO.totalBalance || 0, 'success')
      } catch (e) {
        if (e.response.data.message.includes('Unauthorized Error')) {
          this.$error('نیازمند به ورود',)
          this.vandarModal = true
        } else {
          const err = JSON.parse(e.response.data.errors[0].match(/\[{(.*?)\}]/)[0])[0]
          this.$error(err.error, err.errors.amount)
        }
      }
    },
    async deposit(wallet) {
      this.depositRequest = {txid: ''}
      this.depositRequest = {...this.depositRequest, ...wallet}
      const res = await this.$axios.get('/wallets/to-deposit-address', {
        params: {
          ...this.depositRequest,
        }
      })
      this.QRcode = res.data.baseDTO.qrCode
      this.tag = res.data.baseDTO.tag
      this.depositAddress = res.data.baseDTO.address
      this.cryptoName = this.$coinLabel[wallet.relatedCoin]
      this.cryptoUnit = this.$coinUnit[wallet.relatedCoin]
      this.$refs['dip-modal'].show();
    },
    async mnemonic(wallet) {
      this.mnRequest = wallet
      this.mnRequest.mnemonic = ''
      this.$refs['mn-modal'].show();
    },
    async getMnemonic() {
      if (this.mnRequest.password && !this.loading) {
        this.loading = true
        await this.$axios.post('/wallets/mnemonic', this.mnRequest)
            .then((res) => {
              this.loading = false
              this.mnRequest.mnemonic = res.data?.baseDTO?.mnemonic
            })
            .catch(() => {
              this.loading = false
            })
      }
    },
    async checkWallet() {
      if (this.$ethBase.includes(this.depositRequest.relatedCoin) && !this.depositRequest.txid) {
        return false
      }
      this.loading = true

      const res = await this.$axios.get('/wallets/check-confirmed-transaction-admin', {
        params: this.depositRequest
      })
      let text
      if (res?.data?.baseDTO?.amount) {
        text = `مقدار ${this.$toLocal(res?.data?.baseDTO?.amount) || 0} ${this.$coinLabel[this.relatedCoin[0]]} واریز شده و تایید ${res?.data?.baseDTO?.transactionIsDone ? 'شده' : 'نشده'}`
        this.dipModal = false
      } else {
        text = 'مقدار واریزی شناسایی نشد'
      }
      this.loading = false
      this.$error('', text, res?.data?.baseDTO?.transactionIsDone ? 'success' : 'info')
    },
    withdraw(wallet) {
      this.withdrawRequest = {
        id: '',
        password: '',
        value: '',
        outputAddress: '',
        tokenType: '',
        tag: '',
      }
      this.withdrawRequest = {...this.withdrawRequest, ...wallet}
      this.$refs['with-modal'].show();
    },
    async submitWithdraw() {
      if (this.allowWithdraw && !this.loading) {
        this.withdrawRequest.value = this.$S2N(this.withdrawRequest.value)
        this.loading = true
        const address = ['STELLAR', 'RIPPLE'].includes(this.cryptoUnit) ? '/wallets/account-transaction' : '/wallets/hd/transaction'
        await this.$axios.post(address, this.withdrawRequest)
            .then(() => {
              this.loading = false
              this.$swal({
                icon: 'success',
                title: 'مقدار درخواستی انتقال یافت',
                confirmButtonText: 'تایید',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => {
                this.withModal = false
              })
            })
            .catch(() => {
              this.loading = false
            })
      }
    },
    createWallet() {
      this.$swal({
        title: 'لطفا توجه فرمایید',
        text: "کلید امنیتی و یا رمز عبور کیف پول شما جهت امنیت بیشتر در جایی ذخیره نشده و قابل بازیابی و اصلاح نمیباشد و در صورت فراموشی تمام موجودی آن از دسترس خارج شده و به هیچ وجه قابل بازیابی نمیباشد.",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'متوجه شدم و مسئولیت آن را می پذیرم',
        cancelButtonText: 'لغو',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.state.loading = true
          let res = await this.$axios('/coins/supporting-coins')
          res = res.data
          this.coins = []
          const array = Array.isArray(res)
          for (let data in res) {
            if (data !== 'TOMAN')
              this.coins.push({
                label: this.$coinLabel[array ? res[data] : data],
                value: array ? res[data] : data
              })
          }
          this.newWalletModal = true
        }
      })
    },
    async addWallet() {
      if (this.allowCreateWallet && !this.loading) {
        this.loading = true
        const [res,] = await this.$http.post('/wallets', this.wallet)
        this.loading = false
        if (res) {
          this.$swal({
            icon: 'success',
            title: 'کیف پول ساخته شد',
            confirmButtonText: 'تایید',
            // text: ['STELLAR', 'RIPPLE'].includes(this.wallet.relatedCoin) ? '' : 'mnemonic : ' + res?.data?.baseDTO?.mnemonic,
            text: '',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(() => {
            this.newWalletModal = false
          })
          this.getData()
        }
        this.wallet = {walletName: '', password: '', relatedCoin: 'BITCOIN', addressCount: 1}
      }
    },
    async changePassModal(e) {
      this.confirmPass = ''
      this.selectedWallet = {password: '', oldPassword: '', ...e}
      this.changeModal = true
    },
    async changePass() {
      if (this.allowChangePass && !this.loading) {
        this.loading = true
        const [res,] = await this.$http.post('/wallets/change-password', this.selectedWallet)
        this.loading = false
        if (res) {
          this.$swal({
            icon: 'success',
            title: 'رمز کیف پول تغییر کرد.',
            confirmButtonText: 'تایید',
            // text: ['STELLAR', 'RIPPLE'].includes(this.wallet.relatedCoin) ? '' : 'mnemonic : ' + res?.data?.baseDTO?.mnemonic,
            text: '',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(() => {
            this.changeModal = false
          })
          this.getData()
        }
        this.selectedWallet = {walletName: '', password: '', relatedCoin: 'BITCOIN', oldPassword: ''}
      }
    },
    async coinAccumulation(e) {
      this.minFee = 0
      if (e.relatedCoin === 'TETHER') {
        this.state.loading = true
        const [res,] = await this.$http.get('wallets/min-tether-by-currency/' + e.id)
        this.minFee = res?.data?.minTetherCurrency || 0
      }
      this.accumulateWallet = {
        password: '',
        minBalance: '',
        maxBurn: '',
        label: ''
      }
      this.accumulateWallet = {...this.accumulateWallet, ...e}
      this.accumulateModal = true
    },
    async accumulate() {
      if (this.allowAccumulate && !this.loading) {
        this.loading = true
        const [res,] = await this.$http.post('/wallets/create-gather-request', this.accumulateWallet)
        this.loading = false
        if (res) {
          this.$swal({
            icon: 'success',
            title: 'درخواست تجمیع به شماره ' + res?.data?.baseDTO?.label + ' ثبت شد.',
            confirmButtonText: 'تایید',
            // text: '',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(() => {
            this.newWalletModal = false
          })
          this.getData()
        }
      }

    },

    sort(e) {

      let sort = this.$toSnakeCase(e.sortBy)
      // let sort = e.sortBy
      let sorting = e.sortDesc ? 'DESC' : 'ASC'

      this.$router.push({
        query: {
          ...this.$route.query,
          orderBy: sort,
          sorting: sorting
        }
      })

      this.getData()

    },

    async getData() {
      this.state.loading = true

      let queryParams = {
        ...this.$route.query,
      }

      const res = await this.$axios.get(
          '/wallets',
          {
            params: queryParams
          }
      )

      this.state.loading = false
      this.items = res.data.content
    },
  },
  created() {
    this.getData()
  }
}
</script>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}
</style>
