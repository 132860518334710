<template>
    <b-card
            no-body
            class="p-1"
    >
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <search-and-filter
                    :is-active.sync="isActive"
                    :options="columns"
                    @filter="getData(1, perPage)"
                    @reset="getData(1, perPage)"
            />

            <!--crypto deposit-->
            <b-modal
                    ref="crypto-modal"
                    title="پرداخت رمزارز"
                    ok-title="تایید پرداخت"
                    cancel-title="لغو"
                    v-model="cryptoModal"
                    @ok.prevent="PaymentAlert(selectedWallet.walletName,selectedWallet.totalBalance)"
                    @cancel="selectedWallet=''"
            >
                <b-form style="min-height: 250px" @click.stop.prevent="cryptoModal=true">
                    <b-form-group label="انتخاب کیف پول">
                        <v-select
                                v-model="selectedWallet"
                                dir="rtl"
                                :options="wallets"
                                :reduce="val => val.totalBalance<request.coinAmount ? '' : val"
                                :clearable="false"
                                input-id="user-role"
                                label="walletName"
                        >
                            <template #selected-option="option">
                            <span class="d-flex justify-content-between" style="width: 100%">
                                {{walletName + ' ' + option.walletName}}
                                <b-badge
                                        dir="ltr"
                                        :variant="option.totalBalance<request.coinAmount?'secondary':'success'">{{option.totalBalance || 0 + ' ' + $coinUnit[walletUnit]}}
                                </b-badge>
                            </span>
                            </template>
                            <template #option="option">
                            <span class="d-flex justify-content-between">
                                 {{option.walletName}}
                                <b-badge
                                        dir="ltr"
                                        class="mr-2"
                                        :variant="option.totalBalance<request.coinAmount?'secondary':'success'">{{option.totalBalance || 0 + ' ' + $coinUnit[walletUnit]}}
                                </b-badge>
                            </span>
                            </template>
                        </v-select>
                    </b-form-group>
                    <transition name="fade">
                        <b-form-group class="mt-1" label="رمز کیف پول" v-if="selectedWallet">
                            <b-form-input
                                    type="password"
                                    placeholder="رمز"
                                    v-model="password"
                            />
                        </b-form-group>
                    </transition>
                    <b-form-group class="mt-1" label="مقدار درخواستی">
                        <b-form-input
                                type="text"
                                :value="request.coinAmount"
                                readonly
                        />
                    </b-form-group>
                </b-form>
            </b-modal>

            <b-modal
                    ref="buyer-modal"
                    title="اطلاعات خریدار"
                    hide-footer
                    v-model="buyerModal"
            >
                <template v-for="(data,index) in item">
                    <div v-if="buyerLabel(index)" :key="data" class="d-flex justify-content-between align-items-center">
                        <p>{{buyerLabel(index)}}</p>
                        <p v-if="index === 'buyerBirthdate'">{{$G2J(data,'faDate')}}</p>
                        <p v-else>{{data}}</p>
                    </div>
                </template>
            </b-modal>

            <b-modal
                    ref="receiver-modal"
                    title="اطلاعات دریافت کننده"
                    hide-footer
                    v-model="receiverModal"
            >
                <template v-for="(data,index) in item">
                    <div v-if="receiverLabel(index)" :key="data"
                         class="d-flex justify-content-between align-items-center">
                        <p>{{receiverLabel(index)}}</p>
                        <!--                        <p>{{index}}</p>-->
                        <p v-if="index.includes('deliveryTime')">{{$G2J(data,'faDateTime')}}</p>
                        <p v-else>{{data}}</p>
                    </div>
                </template>
            </b-modal>

            <b-modal
                    ref="qrcode-modal"
                    title="اطلاعات خریدار"
                    hide-footer
                    v-model="qrcodeModal"
            >
                <QRCanvas style="margin-right: 50%;transform: translateX(50%);" :options="options"/>
                <p class="text-center mb-0 mt-1">محتوی بارکد</p>
                <a class="full-width text-center d-block" :href="options.data">{{options.data}}</a>
            </b-modal>

            <div class="m-1 d-flex justify-content-between"
                 dir="rtl"
            >
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
                    فیلتر
                    <feather-icon icon="FilterIcon"/>
                </b-button>
            </div>

            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="true"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        @sort-changed="sort($event)"
                        no-local-sort
                        style="white-space: nowrap; min-height : 235px"
                >

                    <template #cell(coinAmount)="data">
                        <span dir="ltr">
                            {{$toLocal(data.item.coinAmount,$decimal[data.item.relatedCoin])}}
                        </span>
                    </template>

                    <template #cell(tomanAmount)="data">
                        <span dir="ltr">
                            {{$toLocal(data.item.tomanAmount,0)}}
                        </span>
                    </template>

                    <template #cell(relatedCoin)="data">
                        <b-avatar
                                size="25"
                                class="mr-50"
                                :src="require(`@/assets/images/Coins/${data.item.relatedCoin}.png`)"
                                variant="light-info"
                        />
                        {{$coinLabel[data.item.relatedCoin]}}
                    </template>

                    <template #cell(qrCodePath)="{item}">
                        <b-badge v-if="item.qrCodePath" variant="light-primary" pill class="cursor-pointer"
                                 @click="openQrcodeModal(item.qrCodePath)">مشاهده بارکد
                        </b-badge>
                        <i v-else>---</i>
                    </template>

                    <template #cell(action)="{item,index}">
                        <template v-if="$havePermission('CREATE_WALLET') || $havePermission('DELETE_ORDERS')">
                            <b-dropdown
                                    id="dropdown-offset"
                                    variant="link"
                                    no-caret
                                    offset="80px"
                                    :right="true"
                            >
                                <template #button-content>
                                    <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="align-middle text-body"
                                    />
                                </template>
                                <b-dropdown-item v-if="$havePermission('DELETE_ORDERS')"
                                                 @click="openBuyerModal(index)">
                                    <span class="align-middle ml-50">جزییات خریدار</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="$havePermission('DELETE_ORDERS')"
                                                 @click="openReceiverModal(index)">
                                    <span class="align-middle ml-50">جزییات گیرنده</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="$havePermission('CREATE_WALLET')"
                                                 @click="item.relatedCoin==='TOMAN'?
                                    selectPaymentModal([$toLocal(item.amount,$decimal[item.relatedCoin]),item.destinationWalletAddress,item.id])
                                    : PaymentModal($coinLabel[item.relatedCoin],item.relatedCoin,index)"
                                >
                                    <feather-icon class="text-success" icon="CheckSquareIcon"/>
                                    <span class="text-success align-middle ml-50">انجام تراکنش</span>
                                </b-dropdown-item>

                                <!--<b-dropdown-item v-if="$havePermission('DELETE_ORDERS')"
                                                 @click="cancelRequest(index)">
                                    <feather-icon class="text-danger" icon="XCircleIcon"/>
                                    <span class="text-danger align-middle ml-50">لغو درخواست</span>
                                </b-dropdown-item>-->

                            </b-dropdown>
                        </template>
                        <not-allowed v-else/>
                    </template>

                </b-table>
            </div>

            <!-- pagination -->
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
                    dir="rtl"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>

                <div>
                    <label for="perpage">تعداد در صفحه</label>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(1,perPage)"
                    />
                </div>
            </div>

        </b-overlay>
    </b-card>
</template>

<script>
    import {
        BPagination,
        BCard,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BBadge,
        BAvatar,
        BForm,
        BFormInput,
        BFormGroup,
        BOverlay,
    } from 'bootstrap-vue'
    import SearchAndFilter from "@/layouts/components/SearchAndFilter";
    import vSelect from 'vue-select';
    import NotAllowed from "@/layouts/components/NotAllowed";
    import {QRCanvas} from 'qrcanvas-vue';

    export default {
        name: 'GiftCards',
        components: {
            QRCanvas,
            NotAllowed,
            SearchAndFilter,
            BPagination,
            BCard,
            BButton,
            BTable,
            BDropdown,
            BDropdownItem,
            BBadge,
            BAvatar,
            vSelect,
            BForm,
            BFormInput,
            BFormGroup,
            BOverlay,

        },
        data: () => ({
            options: {
                cellSize: 8,
                correctLevel: 'H',
                data: 'test',
            },
            test: 0,
            pageLength: 3,
            dir: false,
            searchTerm: '',
            currentPage: 1,
            perPage: 10,
            rows: 20,
            sortBy: '',
            isSortDirDesc: '',
            userData: [],
            label: '',
            transactionHash: '',
            tomanModal: false,
            vandarModal: false,
            cryptoModal: false,
            buyerModal: false,
            receiverModal: false,
            qrcodeModal: false,
            isActive: false,
            perPageOptions: [5, 10, 20, 50, 100],
            items: [],
            item: {},
            vandar: {
                mobile: '',
                password: ''
            },
            columns: [
                {
                    label: 'نام خریدار',
                    key: 'buyerName',
                    sortable: true,
                    searchType: 'text'
                },
                {
                    label: 'نوع کارت',
                    key: 'creditCardType',
                    sortable: true,
                    searchType: 'select',
                    selectOptions: [
                        {label: 'طلایی', value: 'GOLD'},
                        {label: 'نقره ای', value: 'SILVER'},
                        {label: 'برنزی', value: 'BRONZE'},
                    ]
                },
                {
                    label: 'مقدار ارز',
                    key: 'coinAmount',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: 'واحد درخواست',
                    key: 'relatedCoin',
                    sortable: true,
                    searchType: 'select',
                    selectOptions: [
                        {label: 'بیتکوین', value: 'BITCOIN'},
                        {label: 'اتریوم', value: 'ETHEREUM'},
                        {label: 'بیتکوین کش', value: 'BITCOIN_CASH'},
                        {label: 'لایت کوین', value: 'LITE_COIN'},
                        {label: 'دوج کوین', value: 'DOGE_COIN'},
                        {label: 'اتریوم کلاسیک', value: 'CLASSIC_ETHEREUM'},
                        {label: 'دش کوین', value: 'DASH'},
                    ]
                },
                {
                    label: 'ارزش کارت',
                    key: 'tomanAmount',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: 'وضعیت پرداخت',
                    key: 'tomanAmount',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: 'لینک بارکد',
                    key: 'qrCodePath',
                },
                {
                    label: 'عملیات',
                    key: 'action'
                },
            ],
            walletName: '',
            walletUnit: '',
            request: '',
            selectedWallet: '',
            walletsList: '',
            password: '',
            loading: false,
            wallets: [],
        }),
        computed: {
            statusVariant() {
                const a = {
                    inactive: 'danger',
                    active: 'success',
                }
                return e => a[e];
            },
            statusLabel() {
                const a = {
                    inactive: 'لغو شده',
                    active: 'انجام شده',
                }
                return e => a[e];
            },
            buyerLabel() {
                const a = {
                    isPaid: 'پرداخت شده',
                    buyerName: 'نام و نام خانوادگی',
                    buyerBirthdate: 'تاریخ تولد',
                    buyerMobileNumber: 'شماره موبایل',
                    buyerNationalCode: 'کد ملی',
                    description: 'توضیحات'
                }
                return e => a[e]
            },
            receiverLabel() {
                const a = {
                    destinationAddress: 'آدرس مقصد',
                    deliveryTimeTo: 'زمان دریافت تا',
                    deliveryTimeFrom: 'زمان دریافت از',
                    receiverAddress: 'آدرس',
                    receiverPostalCode: 'کد پستی',
                    receiverTelephone: 'شماره ثابت',
                    receiverName: 'نام و نام خانوادگی',
                    isUsed: 'استفاده شده',
                }
                return e => a[e]
            }
        },
        methods: {

            openQrcodeModal(e) {
                this.options = Object.assign({}, this.options, {
                    data: e,
                });
                this.qrcodeModal = true
            },

            openBuyerModal(e) {
                this.item = this.items[e]
                this.buyerModal = true
            },

            openReceiverModal(e) {
                this.item = this.items[e]
                this.receiverModal = true
            },

            PaymentAlert(e, a) {
                this.label = e
                this.$swal({
                    title: 'موجودی کیف پول ' + this.walletName + ' ' + e + ' : ' + this.$toLocal(a),
                    text: "آیا از انجام تراکنش مطمئن هستید؟",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'انجام تراکنش',
                    cancelButtonText: 'خیر',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                }).then(async (result) => {
                    if (result.value) {
                        this.state.loading = true
                        const address = '/credit-card/credit-card-request'
                        await this.$axios.post(address, {
                            walletName: this.selectedWallet.walletName,
                            creditCardId: this.request.id,
                            password: this.password,
                        })

                        this.getData()

                        this.cryptoModal = false

                        this.$swal({
                            icon: 'success',
                            title: 'تراکنش انجام شد',
                            confirmButtonText: 'تایید',
                            // text: 'Your file has been deleted.',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    }
                })
            },

            async PaymentModal(l, u, i) {
                this.state.loading = true
                this.walletName = l
                this.walletUnit = u

                const res = await this.$axios('/wallets?relatedCoin=' + u)

                this.wallets = res.data.content

                this.request = this.items[i]

                this.cryptoModal = true
            },

            cancelRequest(e) {
                const a = this.items[e]
                this.$swal({
                    icon: 'error',
                    title: "آیا از لغو درخواست کاربر مطمئن هستید؟",
                    confirmButtonText: 'تایید و لغو درخواست',
                    showCancelButton: true,
                    cancelButtonText: 'خیر',
                    text: "مشخصات درخواست : کاربر " + a.customer + " مقدار " + this.$toLocal(a.amount) + " " + this.$coinLabel[a.relatedCoin],
                    customClass: {
                        confirmButton: 'btn btn-danger',
                        cancelButton: 'btn btn-primary ml-1',
                    },
                }).then(async (result) => {
                    if (result.value) {
                        this.state.loading = true
                        const address = '/wallets/withdrawal-request/cancel/' + this.items[e].id
                        const res = await this.$axios.post(address)

                        console.log(res.data.baseDTO)

                        await this.getData(this.currentPage, this.perPage)

                        this.$swal({
                            icon: 'success',
                            title: 'سفارش لغو شد',
                            confirmButtonText: 'تایید',
                            // text: 'Your file has been deleted.',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    }
                })
            },

            sort(e) {
                console.log(e)

                let sort = this.$toSnakeCase(e.sortBy)
                // let sort = e.sortBy
                let sorting = e.sortDesc ? 'DESC' : 'ASC'

                this.$router.push({
                    query: {
                        ...this.$route.query,
                        orderBy: sort,
                        sorting: sorting
                    }
                })

                this.getData(1, this.perPage)

            },

            async getData(page = 1, perPage = this.perPage) {
                this.state.loading = true

                const queryParams = {
                    size: perPage,
                    page: page,
                    ...this.$route.query
                }
                const res = await this.$axios.get('/credit-card', {params: queryParams})

                this.state.loading = false
                this.items = res.data.content
                this.currentPage = res.data.number + 1
                this.rows = res.data.totalElements
            },
        },
        created() {
            this.getData()
        }
    }
</script>

<style scoped>

</style>