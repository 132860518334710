export function authVariant() {
    const a = {
        NOT_COMPLETE: 'secondary',
        UNAUTHORIZED: 'danger',
        PENDING: 'warning',
        AUTHORIZED: 'success',
        FULL_AUTHORIZED: 'success',
        CONFIRMED_IDENTITY: 'info',
        UNIDENTIFIED: 'danger',
        INFORMATION_CHANGED_PENDING: 'warning',
        INFORMATION_CHANGED: 'secondary',
    }
    return e => a[e];
}
export function authLabel() {
    const a = {
        NOT_COMPLETE: 'تکمیل نشده',
        UNAUTHORIZED: 'تایید نشده',
        PENDING: 'در انتظار تایید',
        AUTHORIZED: 'تایید شده',
        FULL_AUTHORIZED: 'تایید شده کامل',
        CONFIRMED_IDENTITY: 'هوبت تایید شده',
        UNIDENTIFIED: 'هوبت تایید نشده',
        INFORMATION_CHANGED_PENDING: 'در انتظار تایید',
        INFORMATION_CHANGED: 'تکمیل نشده',
    }
    return e => a[e]
}

export function statusVariant() {
    return e => e ? 'success' : 'danger'
}
export function statusLabel() {
    return e => e ? 'بله' : 'خیر'
}
export function orderVariant() {
    return e => e === 'BUY' ? 'success' : 'danger'
}
export function orderLabel() {
    return e => e === 'BUY' ? 'خرید' : 'فروش'
}

function pad(num) {
    return ("0"+num).slice(-2);
}

export function hhmmss(secs) {
    let minutes = Math.floor(secs / 60);
    secs = secs%60;
    const hours = Math.floor(minutes/60)
    minutes = minutes%60;
    return `${pad(hours)}h:${pad(minutes)}m:${pad(secs)}s`;
    // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
}