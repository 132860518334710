<template>
    <b-card>

        <b-row>

            <!-- User Info: Left col -->
            <b-col
                    cols="21"
                    xl="7"
                    class="d-flex justify-content-between flex-column"
            >
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                    <b-avatar
                            src="@/assets/images/avatars/avatar.jpg"
                            :text="avatarText(userData.firstName)"
                            variant="light-primary"
                            size="135px"
                            :class="{'auth-img':true}"
                            rounded
                    />
                    <div class="d-flex flex-column ml-1" style="width: 100%">
                        <div class="mb-1">
                            <h4 class="mb-0 d-flex">
                                <!--                                {{ userData.fullName }}-->
                                <b-form-group dir="rtl" class="m-0">
                                    <b-form-input class="py-0 px-50 h4 m-0 width-100" v-model="userData.firstName"
                                                  :plaintext="editMode"/>
                                </b-form-group>
                                <b-form-group dir="rtl" class="m-0">
                                    <b-form-input class="py-0 px-50 h4 m-0" v-model="userData.lastName"
                                                  :plaintext="editMode"/>
                                </b-form-group>
                            </h4>
                            <span>
<!--                                {{ userData.email }}-->
                                <b-form-group dir="rtl" class="m-0">
                                    <b-form-input class="py-0 px-50 m-0" v-model="userData.email"
                                                  :plaintext="true"/>
                                </b-form-group>
                            </span>
                            <p class="card-text">
                                <!--                                {{ userData.address }}-->
                                <b-form-group dir="rtl" class="m-0">
                                    <b-form-textarea class="py-0 px-50 m-0 card-text" v-model="userData.address"
                                                     :plaintext="editMode" no-resize rows="2"/>
                                </b-form-group>
                            </p>
                        </div>
                        <div class="d-flex flex-wrap" v-if="$havePermission('UPDATE_USERS')">
                            <b-button
                                    variant="primary"
                                    @click="edit"
                                    class="px-75"
                            >
                                {{editMode? 'ویرایش':'ثبت تغییرات'}}
                            </b-button>
                            <b-button
                                    variant="outline-warning"
                                    class="ml-1 px-75"
                                    @click.exact="$emit('change-auth')"
                                    v-ripple.600="'rgba(210,133,17,0.08)'"
                                    @click.shift="$emit('force-auth')"
                                    v-tooltip="{variant:'danger',title:'جهت تایید نهایی حساب بدون استعلام کاربر، همراه با نگهداشتن کلید shift کلیک بفرمایید.'}"
                            >
                                  احراز هویت
                            </b-button>
                            <b-button
                                    variant="flat-danger"
                                    class="ml-1 btn-icon"
                                    @click="$emit('change-status')"
                                    v-tooltip.hover.top.v-danger="'تغییر وضعیت'"
                                    pill
                            >
                                <feather-icon icon="RefreshCwIcon" />
                            </b-button>
                        </div>
                    </div>
                </div>

                <!-- User Stats -->
                <div class="d-flex align-items-center mt-2">
                    <div class="d-flex align-items-center mr-2">
                        <b-avatar
                                variant="light-primary"
                                rounded
                        >
                            <feather-icon
                                    icon="DollarSignIcon"
                                    size="18"
                            />
                        </b-avatar>
                        <div class="ml-1">
                            <h5 class="mb-0">
                                {{userData.totalTradesVolume ? $toLocal(userData.totalTradesVolume,0) : 0}} تومان
                            </h5>
                            <small>مجموع معاملات</small>
                        </div>
                    </div>

                    <div class="d-flex align-items-center">
                        <b-avatar
                                variant="light-success"
                                rounded
                        >
                            <feather-icon
                                    icon="TrendingUpIcon"
                                    size="18"
                            />
                        </b-avatar>
                        <div class="ml-1">
                            <h5 class="mb-0">
                                {{userData.totalTradesAmount ? $toLocal(userData.totalTradesAmount,0) : 0}}
                            </h5>
                            <small>تعداد معاملات</small>
                        </div>
                    </div>
                </div>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
                    cols="12"
                    xl="5"
            >
                <table class="mt-2 mt-xl-0 w-100">
                    <tr>
                        <th class="pb-50">
                            <feather-icon
                                    icon="UserIcon"
                                    class="mr-75"
                            />
                            <span class="font-weight-bold">نام کاربری</span>
                        </th>
                        <td>
                            <!--                            {{ userData.username }}-->
                            <b-form-group dir="ltr" class="m-0">
                                <b-form-input class="py-0 px-50" v-model="userData.email" :plaintext="true"/>
                            </b-form-group>
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <feather-icon
                                    icon="PhoneCallIcon"
                                    class="mr-75"
                            />
                            <span class="font-weight-bold">تلفن</span>
                        </th>
                        <td>
                            <!--                            {{ userData.contact }}-->
                            <b-form-group dir="ltr" class="m-0">
                                <b-form-input class="py-0 px-50" v-model="userData.telephone" :plaintext="editMode"/>
                            </b-form-group>
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <feather-icon
                                    icon="SmartphoneIcon"
                                    class="mr-75"
                            />
                            <span class="font-weight-bold">تلفن همراه</span>
                        </th>
                        <td>
                            <!--                            {{ userData.contact }}-->
                            <b-form-group dir="ltr" class="m-0">
                                <b-form-input class="py-0 px-50" v-model="userData.mobileNumber" :plaintext="editMode"/>
                            </b-form-group>
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <feather-icon
                                    icon="CalendarIcon"
                                    class="mr-75"
                            />
                            <span class="font-weight-bold">تاریخ تولد</span>
                        </th>
                        <td>
                            <!--                            {{ userData.birthday }}-->
                            <b-form-group dir="ltr" class="m-0">
                                <b-form-input class="py-0 px-50" :value="$G2J(userData.birthDay)" :plaintext="true"/>
                            </b-form-group>
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <feather-icon
                                    icon="UserIcon"
                                    class="mr-75"
                            />
                            <span class="font-weight-bold">کد ملی</span>
                        </th>
                        <td>
                            <!--                            {{ userData.contact }}-->
                            <b-form-group dir="ltr" class="m-0">
                                <b-form-input class="py-0 px-50" v-model="userData.nationalCode" :plaintext="editMode"/>
                            </b-form-group>
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <feather-icon
                                    icon="CheckIcon"
                                    class="mr-75"
                            />
                            <span class="font-weight-bold">وضعیت</span>
                        </th>
                        <td class="pb-50 text-right">
                            <b-badge
                                    pill
                                    :variant="'light-'+statusVariant(userData.isActive)"
                            >
                                {{statusLabel(userData.isActive)}}
                            </b-badge>
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <feather-icon
                                    :icon="userData.authenticationType==='AUTHORIZED'?'ShieldIcon' : 'ShieldOffIcon'"
                                    class="mr-75"
                            />
                            <span class="font-weight-bold">احراز هویت</span>
                        </th>
                        <td class="pb-50 text-right">
                            <b-badge
                                    pill
                                    :variant="'light-'+authVariant(userData.authenticationType)"
                            >
                                {{authLabel(userData.authenticationType)}}
                            </b-badge>
                        </td>
                    </tr>
                </table>
            </b-col>
        </b-row>

    </b-card>
</template>

<script>
    import {
        BCard,
        BButton,
        BAvatar,
        BRow,
        BCol,
        BBadge,
        BFormGroup,
        BFormInput,
        BFormTextarea,
    } from 'bootstrap-vue'
    import {avatarText} from '@core/utils/filter'

    export default {
        components: {
            BCard,
            BButton,
            BRow,
            BCol,
            BAvatar,
            BBadge,
            BFormInput,
            BFormGroup,
            BFormTextarea,
        },
        props: {
            userData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                avatarText,
                editMode: true
            }
        },
        computed: {
            authVariant() {
                const a = {
                    NOT_COMPLETE: 'secondary',
                    UNAUTHORIZED: 'danger',
                    PENDING: 'warning',
                    AUTHORIZED: 'success',
                    FULL_AUTHORIZED: 'success',
                    UNIDENTIFIED: 'danger',
                    CONFIRMED_IDENTITY : 'success'
                }
                return e => a[e];
            },
            authLabel() {
                const a = {
                    NOT_COMPLETE: 'تکمیل نشده',
                    UNAUTHORIZED: 'تایید نشده',
                    PENDING: 'در انتظار تایید',
                    AUTHORIZED: 'تایید شده',
                    FULL_AUTHORIZED: 'تایید شده کامل',
                    UNIDENTIFIED: 'تایید نشده',
                    CONFIRMED_IDENTITY : 'تایید شده'
                }
                return e => a[e];
            },
            statusVariant() {
                return e => e? 'success' : 'danger'
            },
            statusLabel() {
                return e => e? 'فعال' : 'غیر فعال'
            },
        },
        methods:{
            edit(){
                this.editMode = !this.editMode
                if(this.editMode){
                    this.$emit('edit')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .form-control {
        height: 25px;
    }

    .form-control-plaintext {
        height: 20px;
    }

    .h4 {
        font-size: 1.286rem;
        height: 25px;
    }

    textarea {
        &.form-control, &.form-control-plaintext {
            padding: 0 0.5rem !important;
            height: unset;
        }
    }

    /*.auth-img{*/
    /*  transform: translate(calc(50vw - 275px),100%) scale(5);*/
    /*  z-index: 25*/
    /*}*/
</style>
