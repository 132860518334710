<template>
  <b-card no-body class="p-1">
    <b-overlay
        :show="state.loading"
        rounded="sm"
    >
      <!--        <div class="demo-spacing-0 d-flex justify-content-between m-1" dir="rtl">-->
      <!--            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">-->
      <!--                <feather-icon size="16" icon="FilterIcon"/>-->
      <!--                فیلتر-->
      <!--            </b-button>-->
      <!--            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info">-->
      <!--                <feather-icon size="16" icon="UserPlusIcon"/>-->
      <!--                افزودن کیف پول-->
      <!--            </b-button>-->
      <!--        </div>-->
      <!--        <search-and-filter-->
      <!--                :is-active.sync="isActive"-->
      <!--                :options="columns"/>-->
      <div class="relative-over-x">
        <b-table
            ref="refUserListTable"
            :items="items"
            :small="true"
            responsive
            :fields="columns"
            primary-key="id"
            show-empty
            empty-text="اطلاعاتی یافت نشد"
            @sort-dailyChanged="sort($event)"
            no-local-sort
            class="text-nowrap"
            style="min-height : 235px"
        >
          <template #cell(marketType)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="32"
                    :src="require(`@/assets/images/Coins/${data.item.destinationCoin}.png`)"
                    :variant="`light-info`"
                    :to="{ name: 'Markets', params: { id: data.item.id } }"
                />
                <span class="align-self-center px-25"> | </span>
                <b-avatar
                    size="32"
                    :src="require(`@/assets/images/Coins/${data.item.sourceCoin}.png`)"
                    :variant="`light-info`"
                    :to="{ name: 'Markets', params: { id: data.item.id } }"
                />
              </template>
              <b-link
                  :to="{ name: 'Markets', params: { id: data.item.id } }"
                  class="font-weight-bold d-block text-nowrap"
              >
                {{ $coinLabel[data.item.destinationCoin] }} - {{ $coinLabel[data.item.sourceCoin] }}
              </b-link>
              <small class="text-muted">{{ data.item.marketType }}</small>
            </b-media>
          </template>

          <template #cell(realPrice)="data">
                        <span dir="rtl">
                            {{ $toLocal(data.item.realPrice,$decimal[data.item.sourceCoin]) }} {{ $coinLabel[data.item.sourceCoin] }}
                        </span>
          </template>

          <template #cell(lastTradeTime)="data">
                        <span dir="ltr">
                            {{ data.item.lastTradeTime ? $G2J(data.item.lastTradeTime) : 'بدون معامله' }}
                        </span>
          </template>

          <template #cell(dailyChange)="data">
            <b-badge
                pill
                :variant="'light-'+dailyChangeVariant(data.item.dailyChange)"
                class="text-capitalize"
                dir="ltr"
            >
              {{ Math.abs(data.item.dailyChange) + '%' }}
            </b-badge>
            <feather-icon
                :icon="dailyChangeIcon(data.item.dailyChange)"
                size="18"
                class="ml-50"
                :class="`text-${dailyChangeVariant(data.item.dailyChange)}`"
            />
          </template>

          <template #cell(volume)="data">
                        <span dir="rtl">
                            {{ data.item.volume ? $toLocal(data.item.volume,$decimal[data.item.sourceCoin]) + ' ' + $coinLabel[data.item.sourceCoin] : 0 }}
                        </span>
          </template>

          <template #cell(active)="data">
            <b-badge
                pill
                :variant="'light-'+activeVariant(data.item.active)"
                class="text-capitalize"
            >
              {{ activeLabel(data.item.active) }}
            </b-badge>
          </template>

          <template #cell(action)="{item,index}">
            <template
                v-if="$havePermission('DETAILS_MARKETS') ||
                         $havePermission('UPDATE_MARKETS') ||
                          $havePermission('LIST_ORDERS') ||
                           $havePermission('LIST_TRADES')"
            >
              <b-dropdown
                  id="dropdown-offset"
                  variant="link"
                  no-caret
                  offset="80px"
                  :right="true"
              >
                <template #button-content>
                  <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item v-if="$havePermission('DETAILS_MARKET')"
                                 :to="{ name: 'statistics', params: { id: item.marketType } }">
                  <feather-icon icon="TrendingUpIcon"/>
                  <span class="align-middle ml-50">نمودار ها</span>
                </b-dropdown-item>

                <b-dropdown-item v-if="$havePermission('UPDATE_MARKET')"
                                 @click="dailyChangeStatus(index)">
                  <feather-icon icon="RefreshCcwIcon"/>
                  <span class="align-middle ml-50">تغییر وضعیت</span>
                </b-dropdown-item>

                <b-dropdown-item v-if="$havePermission('LIST_ORDERS')"
                                 :to="{ name: 'Orders', query: { MARKET_TYPE: item.marketType } }">
                  <feather-icon icon="ShoppingBagIcon"/>
                  <span class="align-middle ml-50">سفارشات</span>
                </b-dropdown-item>

                <b-dropdown-item v-if="$havePermission('LIST_TRADES')"
                                 :to="{ name: 'Deals', query: { MARKET_TYPE: item.marketType } }">
                  <feather-icon icon="BriefcaseIcon"/>
                  <span class="align-middle ml-50">معاملات</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <not-allowed v-else/>
          </template>

        </b-table>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  // BPagination,
  BCard,
  // BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  BMedia,
  BAvatar,
  BOverlay,
  BLink,
} from 'bootstrap-vue'
import NotAllowed from "@/layouts/components/NotAllowed";
// import SearchAndFilter from "@/layouts/components/SearchAndFilter";
// import vSelect from 'vue-select';

export default {
  name: 'Wallets',
  components: {
    NotAllowed,
    // SearchAndFilter,
    // BPagination,
    BCard,
    // BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BBadge,
    BMedia,
    BAvatar,
    BOverlay,
    BLink,
    // vSelect,
  },
  data: () => ({
    test: 0,
    pageLength: 3,
    dir: false,
    searchTerm: '',
    currentPage: 1,
    perPage: 1,
    rows: 200,
    sortBy: '',
    isSortDirDesc: '',
    userData: [],
    isActive: false,
    perPageOptions: [5, 10, 20, 50, 100],
    items: [],
    columns: [
      {
        label: 'رمز ارز',
        key: 'marketType',
        sortable: false,
        searchType: 'text'
      },
      {
        label: 'آخرین قیمت',
        key: 'realPrice',
        sortable: false,
        // searchType: 'number'
      },
      {
        label: 'تاریخ آخرین قیمت',
        key: 'lastTradeTime',
        sortable: false,
        // searchType: 'number'
      },
      {
        label: 'تغییر روزانه',
        key: 'dailyChange',
        sortable: false,
        // searchType: 'number'
      },
      {
        label: 'حجم روزانه',
        key: 'volume',
        sortable: false,
        searchType: 'number'
      },
      {
        label: 'وضعیت',
        key: 'active',
        sortable: true,
        searchType: 'select',
        selectOptions: [
          {label: 'غیر فعال', value: false},
          {label: 'فعال', value: true}
        ]
      },
      {
        label: 'جزییات',
        key: 'action'
      },
    ],
  }),
  created() {
    this.getData()
  },
  computed: {
    activeVariant() {
      return e => e ? 'success' : 'danger';
    },
    activeLabel() {
      return e => e ? 'فعال' : 'غیر فعال';
    },
    dailyChangeVariant() {
      return e => e < 0 ? 'danger' : e > 0 ? 'success' : 'secondary';
    },
    dailyChangeIcon() {
      return e => e < 0 ? 'ArrowDownCircleIcon' : e > 0 ? 'ArrowUpCircleIcon' : 'CircleIcon';
    },
    coinUnit() {
      const a = {
        TOMAN: 'ریال',
        BITCOIN: 'BTC',
        BITCOIN_CASH: 'BCH',
        ETHEREUM: 'ETH',
        CLASSIC_ETHEREUM: 'ETC',
        LITE_COIN: 'LTC',
        BINANCE_COIN: 'BNB',
        EOS: 'EOS',
        STELLAR: 'XLM',
        RIPPLE: 'XRP',
        TRON: 'TRX',
        DASH: 'DASH',
        TETHER: 'USDT',
      }
      return e => a[e];
    },
  },
  methods: {
    async dailyChangeStatus(e) {
      console.log(this.items[e].active)
      this.$swal({
        title: 'آیا از تغییر وضعیت بازار مطمئن هستید؟',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'بله',
        cancelButtonText: 'خیر',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.state.loading = true
          const address = '/markets/' + (this.items[e].active ? 'de' : '') + 'activate-market'
          const res = await this.$axios.post(address, '', {params: {marketType: this.items[e].marketType}})
          this.state.loading = false
          console.log(res)

          this.items[e].active = !res.data.message.includes('Deactivated')

          this.$swal({
            icon: 'success',
            title: this.items[e].active ? 'بازار فعال شد' : 'بازار غیر فعال شد',
            confirmButtonText: 'تایید',
            // text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    async getData(page, perPage) {
      this.state.loading = true
      const queryParams = {
        size: perPage,
        page: page,
        ...this.$route.query
      }
      const res = await this.$axios('/markets', {params: queryParams})
      this.state.loading = false
      this.items = res.data.content
      this.currentPage = res.data.number + 1
      this.rows = res.data.totalElements
    },
  }
}
</script>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}
</style>
