<template>
  <div id="supervisor-bot">
    <JobList @edit="job=$event"/>
    <CreateBot :job="job" :key="job.name + job.id + job.token" @submitted="job={}"/>
<!--    <SuperVisorList @add-to-bot="addToBot"/>-->
  </div>
</template>

<script>
// import SuperVisorList from "@/views/SuperVisorBot/SuperVisorList";
import JobList from "@/views/SuperVisorBot/JobList";
import CreateBot from "@/views/SuperVisorBot/CreateBot";

export default {
  name: "index",
  components: {
    CreateBot,
    JobList,
    // SuperVisorList,
  },
  data() {
    return {
      job: {}
    }
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>