var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{ref:"refUserListTable",staticClass:"text-nowrap",staticStyle:{"min-height":"235px"},attrs:{"items":_vm.items,"small":true,"responsive":"","fields":_vm.columns,"primary-key":"id","show-empty":"","empty-text":"اطلاعاتی یافت نشد"},scopedSlots:_vm._u([{key:"cell(coin)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":require(("@/assets/images/Coins/" + (data.item.coin) + ".png")),"variant":"light-info"}})]},proxy:true}],null,true)},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(_vm.$coinLabel[data.item.coin])+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$coinUnit[data.item.coin])+" "+_vm._s(data.item.tokenType && data.item.tokenType!=='UNKNOWN' && (" (" + (data.item.tokenType) + ")")))])],1)]}},{key:"cell(transferFee)",fn:function(ref){
var item = ref.item;
return [_c('CryptoCurrencyField',{attrs:{"item":item,"target":"transferFee","label":"کارمزد"}})]}},{key:"cell(minWithdrawAmount)",fn:function(ref){
var item = ref.item;
return [_c('CryptoCurrencyField',{attrs:{"item":item,"target":"minWithdrawAmount","label":"حداقل برداشت"}})]}},{key:"cell(maxWithdrawAmount)",fn:function(ref){
var item = ref.item;
return [_c('CryptoCurrencyField',{attrs:{"item":item,"target":"maxWithdrawAmount","label":"حداکثر برداشت"}})]}},{key:"cell(minAmount)",fn:function(ref){
var item = ref.item;
return [_c('CryptoCurrencyField',{attrs:{"item":item,"target":"minAmount","label":"حداقل سفارش"}})]}},{key:"cell(maxAmount)",fn:function(ref){
var item = ref.item;
return [_c('CryptoCurrencyField',{attrs:{"item":item,"target":"maxAmount","label":"حداکثر سفارش"}})]}},{key:"cell(creditCardFee)",fn:function(ref){
var item = ref.item;
return [_c('CryptoCurrencyField',{attrs:{"item":item,"target":"creditCardFee","label":"کارمزد کارت ها"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }