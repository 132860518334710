<template>
    <b-card no-body class="p-1">
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <div class="demo-spacing-0 d-flex justify-content-between m-1" dir="rtl">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
                    <feather-icon size="16" icon="FilterIcon"/>
                    فیلتر
                </b-button>
                <!--            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info">-->
                <!--                <feather-icon size="17" icon="FilePlusIcon"/>-->
                <!--                افزودن سفارش-->
                <!--            </b-button>-->
            </div>

            <search-and-filter
                    :is-active.sync="isActive"
                    :options="columns"
                    @filter="getData(1,perPage)"
                    @reset="getData(1,perPage)"
            />

            <history-details v-if="false" :is-active.sync="isDetails" :order="items[1] || []"/>

            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="true"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        @sort-changed="sort($event)"
                        no-local-sort
                        style="white-space: nowrap; min-height : 235px"
                >
                    <template #cell(orderType)="data">
                        <b-badge
                                pill
                                :variant="'light-'+typeVariant(data.item.orderType.split('_')[1])"
                                class="text-capitalize"
                        >
                            {{typeLabel(data.item.orderType)}}
                        </b-badge>
                    </template>

                    <template #cell(marketType)="data">
                        <b-avatar
                                size="22"
                                class="my-50"
                                :src="require(`@/assets/images/Coins/${$marketType2relatedCoin(data.item.marketType)}.png`)"
                                :variant="`light-info`"
                        />
                        /
                        <b-avatar
                                size="22"
                                class="my-50"
                                :src="require(`@/assets/images/Coins/${data.item.marketType.includes('TOMAN') ? 'TOMAN' : 'TETHER'}.png`)"
                                :variant="`light-info`"
                        />
                        <!--                - {{coinLabel(data.item.from)+' / '+coinLabel(data.item.to)}}-->
                    </template>

                    <template #cell(amount)="data">
                        <span dir="ltr">
                            {{$toLocal(data.item.amount,$decimal[$marketType2relatedCoin(data.item.marketType)])+' '+ $coinUnit[$marketType2relatedCoin(data.item.marketType)]}}
                        </span>
                    </template>

                    <template #cell(unitPrice)="data">
                        <span dir="rtl">
                            {{$toLocal(data.item.unitPrice,$decimal[$marketType2sourceCoin(data.item.marketType)]) + ' ' + $coinUnit[$marketType2sourceCoin(data.item.marketType)]}}
                        </span>
                    </template>

                    <template #cell(totalPrice)="data">
                        <span dir="rtl">
                            {{$toLocal(data.item.unitPrice*data.item.amount,$decimal[$marketType2sourceCoin(data.item.marketType)]) + ' ' + $coinUnit[$marketType2sourceCoin(data.item.marketType)]}}
                        </span>
                    </template>

                    <template #cell(executedAmount)="data">
                        <span :dir="$marketType2relatedCoin(data.item.marketType)==='TETHER' ? 'rtl' : 'ltr'">
                            {{$toLocal(data.item.executedAmount,$decimal[$marketType2relatedCoin(data.item.marketType)]) + ' ' + $coinUnit[$marketType2relatedCoin(data.item.marketType)]}}
                        </span>
                    </template>

                    <template #cell(executedPercent)="data">
                        <span dir="rtl">
                            {{data.item.executedPercent.toFixed(2) + ' %'}}
                        </span>
                    </template>

                    <template #cell(createdAtDateTime)="data">
                        <span dir="ltr">
                            {{$G2J(data.item.createdAtDateTime)}}
                        </span>
                    </template>

                    <template #cell(expiresAt)="data">
                        <span dir="ltr">
                            {{data.item.expiresAt ? $G2J(data.item.expiresAt,'faDateTime') : 'بدون انقضا'}}
                        </span>
                    </template>

                    <template #cell(orderStatus)="data">
                        <b-badge
                                pill
                                :variant="'light-'+orderStatusVariant(data.item.orderStatus)"
                                class="text-capitalize"
                        >
                            {{orderStatusLabel(data.item.orderStatus)}}
                        </b-badge>
                    </template>

                    <template #cell(action)="{item,index}">
                        <b-dropdown
                                id="dropdown-offset"
                                variant="link"
                                no-caret
                                offset="80px"
                                :right="true"
                                v-if="item.orderStatus==='IS_OPEN' && $havePermission('DELETE_ORDERS')"
                        >
                            <template #button-content>
                                <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                />
                            </template>

                            <b-dropdown-item v-if="item.orderStatus==='IS_OPEN' && $havePermission('DELETE_ORDERS')"
                                             @click="changeStatus(index)">
                                <feather-icon class="text-danger" icon="XCircleIcon"/>
                                <span class="text-danger align-middle ml-50">لغو سفارش</span>
                            </b-dropdown-item>

                            <b-dropdown-item v-if="false" @click="isDetails=true">
                                <feather-icon icon="FileTextIcon"/>
                                <span class="align-middle ml-50">جزییات سفارش</span>
                            </b-dropdown-item>
                        </b-dropdown>
                        <not-allowed v-else/>
                    </template>

                </b-table>
            </div>

            <!-- pagination -->
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
                    dir="rtl"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>
                <div>
                    <label for="perpage">تعداد در صفحه</label>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(currentPage,perPage)"
                    />
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
    import {
        BPagination,
        BCard,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BBadge,
        BAvatar,
        BOverlay,
    } from 'bootstrap-vue'
    import SearchAndFilter from "@/layouts/components/SearchAndFilter";
    import vSelect from 'vue-select';
    import HistoryDetails from "@/views/Orders/historyDetails";
    import NotAllowed from "@/layouts/components/NotAllowed";

    export default {
        name: 'Orders',
        components: {
            NotAllowed,
            HistoryDetails,
            SearchAndFilter,
            BPagination,
            BCard,
            BButton,
            BTable,
            BDropdown,
            BDropdownItem,
            BBadge,
            BAvatar,
            vSelect,
            BOverlay,
        },
        data: () => ({
            test: 0,
            pageLength: 3,
            dir: false,
            searchTerm: '',
            currentPage: 1,
            perPage: 10,
            rows: 20,
            sortBy: '',
            isSortDirDesc: '',
            userData: [],
            isActive: false,
            isDetails: false,
            perPageOptions: [5, 10, 20, 50, 100],
            items: [],
            columns: [
                {
                    label: 'نام کاربری',
                    key: 'customer',
                    sortable: false,
                    searchType: 'text'
                },
                {
                    label: 'نوع سفارش',
                    key: 'orderType',
                    sortable: true,
                    searchType: 'select',
                    selectOptions: [
                        {label: 'حدی - خرید', value: 'LIMITED_BUY'},
                        {label: 'حد - فروش', value: 'LIMITED_SELL'},
                        {label: 'بازار - خرید', value: 'MARKET_BUY'},
                        {label: 'بازار - فروش', value: 'MARKET_SELL'},
                    ]
                },
                {
                    label: 'بازار',
                    key: 'marketType',
                    sortable: true,
                    searchType: 'select',
                    selectOptions: [
                        {label: 'بیتکوین - تومان', value: 'BITCOIN_TOMAN'},
                        {label: 'بیتکوین - تتر', value: 'BITCOIN_TETHER'},
                        {label: 'اتریوم - تومان', value: 'ETHEREUM_TOMAN'},
                        {label: 'اتریوم - تتر', value: 'ETHEREUM_TETHER'},
                        {label: 'اتریوم کلاسیک - تومان', value: 'ETHEREUM_CLASSIC_TOMAN'},
                        {label: 'اتریوم کلاسیک - تتر', value: 'ETHEREUM_CLASSIC_TETHER'},
                        {label: 'لایت کوین - تومان', value: 'LITE_COIN_TOMAN'},
                        {label: 'لایت کوین - تتر', value: 'LITE_COIN_TOMAN'},
                        {label: 'بیتکوین کش - تومان', value: 'BITCOIN_CASH_TOMAN'},
                        {label: 'بیتکوین کش - تتر', value: 'BITCOIN_CASH_TETHER'},
                        {label: 'ریپل - تومان', value: 'RIPPLE_TOMAN'},
                        {label: 'ریپل - تتر', value: 'RIPPLE_TETHER'},
                        {label: 'بایننس کوین - تومان', value: 'BINANCE_COIN_TOMAN'},
                        {label: 'بایننس کوین - تتر', value: 'BINANCE_TETHER'},
                        {label: 'ایاس - تومان', value: 'EOS_TOMAN'},
                        {label: 'ایاس - تتر', value: 'EOS_TETHER'},
                        {label: 'استلار - تومان', value: 'STELLAR_TOMAN'},
                        {label: 'استلار - تتر', value: 'STELLAR_TETHER'},
                        {label: 'ترون - تومان', value: 'TRON_TOMAN'},
                        {label: 'ترون - تتر', value: 'TRON_TETHER'},
                        {label: 'دش - تومان', value: 'DASH_TOMAN'},
                        {label: 'دش - تتر', value: 'DASH_TETHER'},
                        {label: 'تتر - تومان', value: 'TOMAN_TETHER'},
                        {label: 'تومان - تتر', value: 'TETHER_TOMAN'},
                    ]
                },
                {
                    label: 'مقدار',
                    key: 'amount',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: 'قیمت واحد',
                    key: 'unitPrice',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: 'ارزش کل',
                    key: 'totalPrice',
                    sortable: false,
                    // searchType: 'number'
                },
                {
                    label: 'انجام شده',
                    key: 'executedAmount',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: 'انجام شده(درصد)',
                    key: 'executedPercent',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: 'وضعیت',
                    key: 'orderStatus',
                    sortable: true,
                    searchType: 'select',
                    selectOptions: [
                        {label: 'تکمیل شده', value: 'FINISHED'},
                        {label: 'منقضی شده', value: 'EXPIRED'},
                        {label: 'فعال', value: 'IS_OPEN'},
                        {label: 'لغو شده کاربر', value: 'CANCELLED_BY_USER'},
                        {label: 'لغو شده مدیر', value: 'CANCELLED_BY_ADMIN'},
                    ]
                },
                {
                    label: 'تاریخ ایجاد',
                    key: 'createdAtDateTime',
                    sortable: true,
                    searchType: 'date'
                },
                {
                    label: 'تاریخ انقضا',
                    key: 'expiresAt',
                    sortable: false,
                    // searchType: 'date'
                },
                {
                    label: 'عملیات',
                    key: 'action'
                },
            ],
        }),
        computed: {
            orderStatusVariant() {
                const a = {
                    FINISHED: 'success',
                    IS_OPEN: 'warning',
                    CANCELLED_BY_USER: 'danger',
                    CANCELLED_BY_ADMIN: 'danger',
                    EXPIRED: 'danger',
                }
                return e => a[e];
            },
            orderStatusLabel() {
                const a = {
                    FINISHED: 'تکمیل شده',
                    IS_OPEN: 'فعال',
                    CANCELLED_BY_USER: 'لغو شده کاربر',
                    CANCELLED_BY_ADMIN: 'لغو شده مدیر',
                    EXPIRED: 'منقضی شده',
                }
                return e => a[e];
            },
            typeVariant() {
                const a = {
                    BUY: 'success',
                    SELL: 'danger',
                }
                return e => a[e];
            },
            typeLabel() {
                const a = {
                    LIMITED_BUY: 'حد - خرید',
                    LIMITED_SELL: 'حد - فروش',
                    MARKET_BUY: 'بازار - خرید',
                    MARKET_SELL: 'بازار - فروش',
                }
                return e => a[e];
            },
        },
        methods: {
            changeStatus(e) {
                console.log(this.items[e].orderStatus)
                this.$swal({
                    title: 'آیا از لغو سفارش کاربر مطمئن هستید؟',
                    // text: "You won't be able to revert this!",
                    icon: 'error',
                    showCancelButton: true,
                    confirmButtonText: 'بله',
                    cancelButtonText: 'خیر',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                }).then(async (result) => {
                    if (result.value) {

                        this.state.loading = true
                        const address = '/orders/cancel/' + this.items[e].id
                        const res = await this.$axios.delete(address)

                        this.items[e].orderStatus = res.data.message.includes('Canceled') ? 'CANCELLED_BY_ADMIN' : this.items[e].orderStatus

                        this.$swal({
                            icon: 'success',
                            title: 'سفارش غیر فعال شد',
                            confirmButtonText: 'تایید',
                            // text: 'Your file has been deleted.',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })

                    }
                })
            },
            sort(e) {
                console.log(e)

                let sort = this.$toSnakeCase(e.sortBy)
                // let sort = e.sortBy
                let sorting = e.sortDesc ? 'DESC' : 'ASC'

                this.$router.push({
                    query: {
                        ...this.$route.query,
                        orderBy: sort,
                        sorting: sorting
                    }
                })

                this.getData(1, this.perPage)

            },
            async getData(page, perPage) {
                this.state.loading = true
                const queryParams = {
                    size: perPage,
                    page: page,
                    ...this.$route.query
                }
                const res = await this.$axios('/orders/admin', {params: queryParams})
                this.state.loading = false
                this.items = res.data.content
                this.currentPage = res.data.number + 1
                this.rows = res.data.totalElements
            },
        },
        created() {
            this.getData(1, this.perPage)
        }
    }
</script>
<style lang="scss">
    [v-cloak] {
        opacity: 0;
    }

    .nowrap {
        white-space: nowrap;
    }

    [dir="rtl"] .test-badge {
        font-size: 10px;
        top: -6px;
        left: -5px !important;
        min-width: 16px;
        min-height: 16px;
    }
</style>
